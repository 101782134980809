import { gql, useMutation, useQuery } from '@apollo/client'
import { Button, Tab, Tabs, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Link, Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { AdvancedSettings, useResetToReview } from '../DeckSettings/Advanced'
import { DeckSettingsCards } from '../DeckSettings/CardBrowser'
import { DeckSettingsNewCards } from '../DeckSettings/NewCards'
import { GQLGetCourseSettingsQuery, GQLGetReviewsQuery, GQLSetCourseSettingsMutation } from '../generated/client-graphql-types'
import { GET_REVIEWS, useCourseSettings } from '../reviewer/DeckReviewer'
import { TagID } from '../shared/types'
import { DataNotLoaded } from '../Utils'

export const GET_COURSE_SETTINGS = gql`
    query getCourseSettings($courseID: ID!) {
        getCourse(courseID: $courseID) {
            settings {
                minutes
                custom
            }
            id
        }
    }
`

export const UPDATE_COURSE_SETTINGS = gql`
    mutation setCourseSettings($courseID: ID!, $settings: CourseSettingsInput!) {
        setCourseSettings(courseID: $courseID, settings: $settings) {
            settings {
                minutes
                custom
            }
            id
        }
    }
`

export const TopLevelDeckSettings = () => {
    return (
        <DeckSettingsContainer>
            <DeckSettingsMenu></DeckSettingsMenu>
            <Switch>
                <Redirect from="/deck/:deckID/settings" to="/deck/:deckID/settings/home" exact></Redirect>
                <Redirect from="/deck/:deckID/settings/" to="/deck/:deckID/settings/home" exact></Redirect>
                <Redirect from="/deck/:deckID/settings/home" to="/deck/:deckID/settings/newcards" exact></Redirect>
                <Route path="/deck/:deckID/settings/newcards" exact component={DeckSettingsNewCards} />
                <Route path="/deck/:deckID/settings/cards" exact component={DeckSettingsCards} />
                <Route path="/deck/:deckID/settings/Advanced" exact component={AdvancedSettings} />
            </Switch>
        </DeckSettingsContainer>
    )
}

//<Route path="/deck/:deckID/settings/home" exact component={DeckSettingsHome} />
export const DeckSettingsHome = () => {
    const { deckID } = useParams<{ deckID: TagID }>()
    const updateSettings = useMutateCourseSettings(deckID)
    return <div>sup</div>
}

export const DeckSettingsMenu = () => {
    const route = useRouteMatch([
        /*'/deck/:deckID/settings/home',*/
        '/deck/:deckID/settings/newcards',
        '/deck/:deckID/settings/cards',
        '/deck/:deckID/settings/advanced',
    ])
    const currentTab = route?.path

    const base = window.location.pathname.split('settings')[0] + 'settings'

    return (
        <Tabs value={currentTab || `/deck/:deckID/settings/home`}>
            <Tab component={Link} value={`/deck/:deckID/settings/newcards`} to={`${base}/newcards`} label="New Cards"></Tab>
            <Tab component={Link} value={`/deck/:deckID/settings/cards`} to={`${base}/cards`} label="Browse Cards"></Tab>
            <Tab component={Link} value={`/deck/:deckID/settings/advanced`} to={`${base}/advanced`} label="Advanced"></Tab>
        </Tabs>
    )
}
//<Tab component={Link} value={`/deck/:deckID/settings/home`} to={`${base}/home`} label="Home"></Tab>

export const TopLevelSecretDeckSettings = () => {
    const { deckID } = useParams<{ deckID: TagID }>()
    const setSettings = useMutateCourseSettings(deckID)

    const [minutesState, setMinutesState] = useState<string | undefined>(undefined)

    const { data, error, loading } = useQuery<GQLGetCourseSettingsQuery>(GET_COURSE_SETTINGS, {
        variables: { courseID: deckID },
    })

    if (error || loading || !data) {
        return <DataNotLoaded {...{ loading, error, data }} />
    }

    if (minutesState === undefined) setMinutesState('' + data.getCourse?.settings.minutes || undefined)

    return (
        <SecretDeckSettingsContainer>
            <TextField
                variant="outlined"
                label="Minutes"
                value={minutesState}
                onChange={(e) => setMinutesState(e.target.value)}
                type="number"
            ></TextField>
            <Button
                onClick={() => {
                    setSettings({ minutes: parseInt(minutesState!) })
                }}
                variant="outlined"
            >
                Save
            </Button>
        </SecretDeckSettingsContainer>
    )
}

const SecretDeckSettingsContainer = styled.div`
    display: grid;
    justify-content: center;
    align-content: center;
    grid-auto-flow: column;
    grid-gap: 10px;
`

const DeckSettingsContainer = styled.div`
    display: grid;
    width: 100%;
    grid-auto-flow: column;
    grid-template-rows: auto 1fr;
`

export const CommitmentSelectorInternal = styled.div`
    width: 500px;
    max-width: 95vw;
    margin: auto;
    display: grid;
`

export const useMutateCourseSettings = (deckID: TagID) => {
    const resetToReview = useResetToReview()
    //const { refetch: refetchToReview } = useQuery<GQLGetReviewsQuery>(GET_REVIEWS, {
    //    variables: { tagID: deckID },
    //    nextFetchPolicy: 'network-only',
    //})
    const [rawMutateCourseSettings] = useMutation<GQLSetCourseSettingsMutation>(UPDATE_COURSE_SETTINGS)

    return async ({ minutes, custom }: Partial<{ minutes: number; custom: Record<string, any> }>) => {
        await rawMutateCourseSettings({
            variables: {
                courseID: deckID,
                settings: {
                    minutes,
                    custom: custom, //&& JSON.stringify(custom)
                },
            },
            optimisticResponse: {
                __typename: 'Mutation',
                setCourseSettings: {
                    settings: {
                        ...{ minutes, custom },
                        __typename: 'TagSettings',
                    },
                    id: deckID,
                    __typename: 'Course',
                },
            },
            refetchQueries: [
                /*{ query: GET_COMMITMENT_INFO, variables: { courseID: deckID } },*/
                /*{ query: GET_REVIEWS, variables: { tagID: deckID } },*/
            ],
        })
        resetToReview()
        //await refetchToReview()
    }
}

export const GET_COMMITMENT_INFO = gql`
    query getCommitmentInfo($courseID: ID!) {
        getCourse(courseID: $courseID) {
            settings {
                minutes
                custom
            }
        }

        getTagByID(tagID: $courseID) {
            id
            averageTimeByType
        }
    }
`
