import { ApolloError, gql, useQuery } from '@apollo/client'
import { CircularProgress } from '@material-ui/core'
import Alert from 'mdi-material-ui/Alert'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { GQLNeedsUpdateQuery } from './generated/client-graphql-types'
import { isProd, LATEST_VERSION } from './shared/sharedutil'

export function useWindowSize() {
    const isClient = typeof window === 'object'

    function getSize() {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined,
        }
    }

    const [windowSize, setWindowSize] = useState(getSize)

    useEffect(() => {
        if (!isClient) {
            //return false;
            return
        }

        function handleResize() {
            setWindowSize(getSize())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, []) // Empty array ensures that effect is only run on mount and unmount

    return windowSize
}

const EmptyPlaceholderContainer = styled.div`
    display: grid;
    align-items: center;
    justify-items: center;
    align-content: center;
    opacity: 54%;
`

const LargeIconContainer = styled.div`
    font-size: 100px;
`

export const DataNotLoaded = ({
    loading,
    error,
    data,
}: {
    loading: boolean
    error: ApolloError | string | undefined
    data: any
}) => {
    if (loading && !data) {
        return <CenteredLoading />
    } else if (error || !data) {
        let userMessage: string | undefined = ''
        if (!isProd) {
            userMessage = JSON.stringify(error)
        } else if (typeof error === 'string') {
            userMessage = error
        } else {
            userMessage = error?.graphQLErrors[0].message
        }
        return <LargeMessage message={userMessage} icon={Alert} />
    } else {
        return null
    }
}

export const LargeMessage = ({ message, icon }: { message: any; icon: React.FC<any> }) => {
    return (
        <EmptyPlaceholderContainer>
            <LargeIconContainer>{React.createElement(icon, { fontSize: 'inherit', opacity: 0.54 }, null)}</LargeIconContainer>
            {message}
        </EmptyPlaceholderContainer>
    )
}

export const CenteredLoading = () => {
    const [visible, setVisible] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(true)
        }, 1 * 1000)

        return () => clearTimeout(timer)
    }, [])

    if (visible) {
        return <CenteredLoadingIndicator />
    } else {
        return null
    }
}

export const CenteredLoadingIndicator = styled(CircularProgress)`
    color: #6772e5;
    justify-self: center;
    align-self: center;
`

const NEEDS_UPDATE = gql`
    query needsUpdate($version: Int!) {
        needsUpdate(version: $version)
    }
`

export const useStayUpdated = () => {
    const hoursFresh = 0.25
    const { loading, error, data, refetch } = useQuery<GQLNeedsUpdateQuery>(NEEDS_UPDATE, {
        variables: { version: LATEST_VERSION },
    })

    useEffect(() => {
        const handle = setInterval(() => {
            refetch()
        }, hoursFresh * 60 * 60 * 1000) //8 * 60 * 60 * 1000)

        return () => clearTimeout(handle)
    }, [])

    if (!(loading || error) && data?.needsUpdate) {
        window.location.reload()
    }
}

export const useQueryParams = () => {
    const { search } = useLocation()
    return useMemo(() => Object.fromEntries(new URLSearchParams(search)), [search])
}
