// interleave takes two lists of things, and interleaves them with the following properties.
// first, elements from each list should be evenly distributed in the output.
// second, when you pop an element off the top of the interwoven list, and then call interleave again without that new element, all the other elements must maintain their ordering exactly.
// this function is a huge pain to get correct and ends up requiring persistent state :(.

import _ from 'lodash'

export type PairInterleaver<T> = (longer: T[], shorter: T[]) => T[]
type PairInterleaveState = { ratio: number | null }
export type Interleaver<T> = (inputs: T[][]) => T[]
type InterleaverState<T> = PairInterleaver<T>[]

export const makePairInterleaver = <T>(): PairInterleaver<T> => {
    let state: PairInterleaveState = { ratio: null }

    return (longer: T[], shorter: T[]): T[] => {
        const [out, newState] = pairInterleave(longer, shorter, state)
        state = newState
        return out
    }
}

const pairInterleave = <T>(longer: T[], shorter: T[], state: PairInterleaveState): [T[], PairInterleaveState] => {
    if (!state.ratio) {
        state.ratio = longer.length / (shorter.length + 0.00001)
    }
    const ratio = state.ratio

    let out: T[] = []

    const takeFromLonger = (n: number) => {
        out.push(..._.take(longer, n))
        longer = _.drop(longer, n)
    }

    const takeFromShorter = (n: number) => {
        out.push(..._.take(shorter, n))
        shorter = _.drop(shorter, n)
    }

    while (!(longer.length === 0 && shorter.length === 0)) {
        if (shorter.length === 0) {
            takeFromLonger(1)
        } else if (longer.length === 0) {
            takeFromShorter(1)
        } else if (longer.length / shorter.length >= ratio) {
            takeFromLonger(1)
        } else {
            takeFromShorter(1)
        }
    }

    return [out, state]
}

export const makeInterleaver = <T>(distinctTypes: number): Interleaver<T> => {
    const interleavers = _.times(distinctTypes, () => makePairInterleaver<T>())

    return (inputs: T[][]) => {
        let soFar: Array<T> = []
        inputs.forEach((input, index) => {
            soFar = interleavers[index](soFar, input)
        })
        return soFar
    }
}
