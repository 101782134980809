import { gql, useMutation, useQuery } from '@apollo/client'
import { SettingsInputAntennaTwoTone } from '@material-ui/icons'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ME } from '../App'
import { ClientCard } from '../client_types'
import { useReviews } from '../DeckPreview/DeckPreview'
import { GET_COURSE_SETTINGS, UPDATE_COURSE_SETTINGS, useMutateCourseSettings } from '../DeckPreview/DeckSettings'
import { ALL_CARD_FIELDS_FRAGMENT } from '../fragments'
import {
    GQLGetCourseSettingsQuery,
    GQLSetCourseSettingsMutation,
    GQLTagSettings,
    GQLUpdateSettingsMutation,
} from '../generated/client-graphql-types'
import { UserContext } from '../Login'
import { Interleaver, makeInterleaver } from '../shared/interleaver'
import { backendURL, leftToReview, startOfNextSRSDay } from '../shared/sharedutil'
import { newDate } from '../shared/times'
import { AnswerType, ClientReviewInfo, CustomSettings, TagID, UserSettings } from '../shared/types'
import { LiveCardMeta } from '../types'
import { DataNotLoaded } from '../Utils'
import { EndOfDeckCard, ReviewableCard } from './Card'
import { ReviewerMeta } from './ReviewerMeta'
import { ReviewerContainer } from './ReviewerShared'
import { TryitReviewer } from './TryItReviewer'
import { ExperienceSelector, WelcomeReviewer } from './WelcomeFlow'

export const GET_ALL_CARDS = gql`
    query getAllCards($tagID: ID!) {
        getAllCards(tagID: $tagID) {
            id
            type
            source

            comments {
                author {
                    displayName
                    picture
                    id
                }
                contents
                likes
                timeCreated
            }
        }
    }
`

export const GET_REVIEWS = gql`
    ${ALL_CARD_FIELDS_FRAGMENT}
    query getReviews($tagID: ID!) {
        toReview(tagID: $tagID) {
            ...allCardFields
        }
    }
`

export const GET_REVIEWS_NEWS_ONLY = gql`
    ${ALL_CARD_FIELDS_FRAGMENT}
    query getReviewsNewsOnly($tagID: ID!, $startIdx: Int, $endIdx: Int) {
        toReview(tagID: $tagID, onlyNews: true, startIdx: $startIdx, endIdx: $endIdx) {
            ...allCardFields
        }
    }
`

const UPDATE_SETTINGS = gql`
    mutation updateSettings($settings: UserSettingsInput!) {
        updateSettings(settings: $settings) {
            success
        }
    }
`

export const TopLevelDeckReviewer = (props: any) => <DeckReviewerSplitter deckID={props.match.params.deckID} />
export const TopLevelDeckTryer = (props: any) => <TryitReviewer deckID={props.match.params.deckID} />

const DeckReviewerSplitter = ({ deckID }: { deckID: string }) => {
    const user = useContext(UserContext)

    const [didDeckWelcome, setDidDeckWelcome] = useState(false)
    //const [didCommitmentScreen, setDidCommitmentScreen] = useState(false)

    const mutateUserSettings = useMutateUserSettings()

    const { loading, error, data } = useCourseSettings(deckID)

    if (loading || error || !data) {
        return <DataNotLoaded {...{ loading, error, data }} />
    }

    if (user?.settings.needsDeckWelcome && !didDeckWelcome) {
        return (
            <WelcomeReviewer
                done={() => {
                    mutateUserSettings({
                        needsDeckWelcome: false,
                    })
                    setDidDeckWelcome(true)
                }}
            />
        )
    }

    /*
    if (
        deckID === '51acd7039addb994' &&
        !data?.custom?.previouslyStudied &&
        newDate(user?.createdTime).isAfter(newDate('2021-07-27 00:00:00.000Z'))
    ) {
        return <ExperienceSelector deckID={deckID}></ExperienceSelector>
    }
    */

    return <DeckReviewer deckID={deckID} />
}

export const useMutateUserSettings = () => {
    const [mutateSettingsRaw] = useMutation<GQLUpdateSettingsMutation>(UPDATE_SETTINGS)

    const mutateSettings = (updates: Partial<UserSettings>) =>
        mutateSettingsRaw({
            variables: {
                settings: updates,
            },
            refetchQueries: [{ query: ME }],
            awaitRefetchQueries: true,
        })

    return mutateSettings
}

export const useCourseSettings = (deckID: string) => {
    const { data, loading, error } = useQuery<GQLGetCourseSettingsQuery>(GET_COURSE_SETTINGS, {
        variables: {
            courseID: deckID,
        },
    })

    const out = data?.getCourse?.settings as Omit<GQLTagSettings, 'custom'> & { custom: CustomSettings }

    return { data: out, loading, error }
}

export const DeckFinished = () => {
    const { deckID } = useParams<{ deckID: TagID }>()
    const history = useHistory()

    const { loading, error, data } = useCourseSettings(deckID)

    if (loading || error || !data) {
        return <DataNotLoaded {...{ loading, error, data }} />
    }
    const minutes = data?.minutes

    if (!minutes) {
        history.push(`/deck/${deckID}/settings`)
    }

    return <EndOfDeckCard></EndOfDeckCard>
}

// DeckReviewer gets card from DeckID
// CardSetReviewer
// ReviewableCard creates submitReview, setGuess, setFlipped, etc. Adds answering component. Calls adjustLiveCard()
// InnerCard => Card

// DeckReviewer (parses cards)
// CardSetReviewer looks at what the status of cards are, picks one to show, and shows meta info.
// SingleCardReviewer injects submitReview
// ReviewableCard creates setGuess, setCardFlipped, etc, and also adds the answering component.
// Card makes modifications to fields based on guess, trueGuess, explanation, specific_explanation, etc.
// InnerCard splits based on card type
// AllCard displays all fields.

export const DeckReviewer = ({ deckID }: { deckID: string }) => {
    const { loading, error, data, refetch } = useReviews(deckID)

    const user = useContext(UserContext)
    const currentCutoffTime = startOfNextSRSDay(newDate(), user?.settings?.rolloverSecond || 0).unix()

    useEffect(() => {
        refetch()
    }, [refetch, currentCutoffTime])

    if (!user) return null

    if (error || loading || !data) {
        return <DataNotLoaded {...{ loading, error, data }} />
    }

    const cards = data.toReview
    console.info('Reviewing cardset')
    cards.forEach((c) => {
        const out = _.omit(c, ['source', 'front', 'back', 'tags', '__typename', 'comments'])
        //console.info(out) TODO
    })

    return <CardSetReviewer cards={cards} />
}

const useVisibilityObserver = (data: Record<string, any>) => {
    return
    useEffect(() => {
        const onVisibilityChange = () => {
            window.navigator.sendBeacon(
                `${backendURL}/beacon`,
                JSON.stringify({
                    type: 'visibilityChange',
                    event: {
                        value: document.visibilityState,
                        ...data,
                    },
                })
            )
        }
        window.addEventListener('visibilitychange', onVisibilityChange)

        return () => {
            window.removeEventListener('visibilitychange', onVisibilityChange)
        }
    }, [data])
}

export const CardSetReviewer = ({ cards }: { cards: ClientCard[] }) => {
    const user = useContext(UserContext)

    const [interleaver] = useState<Interleaver<ClientCard>>(() => makeInterleaver<ClientCard>(3))

    const { remaining, newLeft, learningLeft, memorizedLeft } = leftToReview(cards, interleaver, user!.settings.rolloverSecond)

    useVisibilityObserver({
        location: 'reviewing',
        cardId: remaining[0]?.id,
        left: { newLeft, learningLeft, memorizedLeft },
        remaining: remaining.length,
    })

    const { enqueueSnackbar } = useSnackbar()
    const [history, setHistory] = useState<(ClientReviewInfo & { cardId: string } & ClientCard)[]>([])
    const [skipNotified, setSkipNotified] = useState<boolean>(false)

    if (
        history.length === 5 &&
        _.every(history, (c) => c.index <= 5) &&
        history.filter((r) => r.answer !== AnswerType.Forgot).length >= 3 &&
        !skipNotified
    ) {
        enqueueSnackbar('Consider using the skip button (hotkey s) to skip cards you already know!', {
            autoHideDuration: 7000,
            anchorOrigin: {
                horizontal: 'left',
                vertical: 'top',
            },
        })
        setSkipNotified(true)
    }

    if (remaining.length === 0) {
        return <DeckFinished></DeckFinished>
    }

    const id = localStorage.getItem('activeCardId')
    const activeCard = remaining.find((c) => c.id === id) || remaining[0]
    localStorage.setItem('activeCardId', activeCard.id)

    const key = `${activeCard.due} + ${activeCard.id} + ${activeCard.timesSeen}`

    const schedulingDebug = _.take(
        remaining.map((c) => _.omit(c, ['source', 'tags', '__typename', 'comments'])),
        20
    )

    const debug = {
        schedulingDebug,
        key,
        left: { newLeft, learningLeft, memorizedLeft },
    }

    return (
        <ReviewerContainer>
            <ReviewableCard
                key={key} // if you don't have this line, the old card flashes for a moment after you click "correct".
                card={activeCard}
                sendOnReview={debug}
                metaComponent={(liveCardMeta: LiveCardMeta) => <ReviewerMeta {...{ cards, activeCard, liveCardMeta }} />}
                onReview={(r) => {
                    if (history.length <= 10) {
                        setHistory(_.concat(history, { ...r, ...activeCard }))
                    }
                }}
            />
        </ReviewerContainer>
    )
}
