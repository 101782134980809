import React from 'react'
import ReactDOM from 'react-dom'
import { WrappedApp } from './App'
import './index.css'
import * as serviceWorker from './serviceWorker'

import amplitude from 'amplitude-js'
amplitude.init('ec59d7f3ec8e26d3937e98b92c73e15f')

ReactDOM.render(<WrappedApp />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
