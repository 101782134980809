import styled from 'styled-components'
import React, { useState } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { gql, useQuery } from '@apollo/client'
import { DataNotLoaded } from '../Utils'
import { AnswerType, CardStatus, FullReviewInfo, FullUserStats } from '../shared/types'
import _ from 'lodash'
import { toUserSRSDay } from '../shared/srsDays'
import { labelOutliers } from './MultiUserStats'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useHistory } from 'react-router-dom'
import { hashStringToNumber, REVIEW_TOO_LONG_TIME_MS } from '../shared/sharedutil'
import { fromUnix, newDate } from '../shared/times'
import { GQLGetUserStatsQuery } from '../generated/client-graphql-types'
dayjs.extend(duration)
dayjs.extend(relativeTime)

const GET_USER_STATS = gql`
    query getUserStats($userId: ID!, $tagId: ID) {
        getUserDeckStats(userId: $userId, tagId: $tagId)
    }
`

const ByUserStatsContainer = styled.div`
    display: grid;
    width: 100%;
    align-content: start;
`
export const TopLevelIndividualUserStats = (props: any) => (
    <IndividualUserStatsPage userId={props.match.params.userID} tagId={props.match.params.tagID} />
)

const formatLeft = (lefts: { newLeft: number; learningLeft: number; memorizedLeft: number }) => {
    if (!lefts) {
        return 'No Left Data'
    }

    const { newLeft, learningLeft, memorizedLeft } = lefts
    return `${newLeft} / ${learningLeft} / ${memorizedLeft}`
}

const IndividualUserStatsPage = ({ userId, tagId }: { userId: string; tagId: string }) => {
    const { data, loading, error } = useQuery<GQLGetUserStatsQuery>(GET_USER_STATS, {
        variables: {
            userId,
            tagId,
        },
    })

    if (error || loading || !data) {
        return <DataNotLoaded {...{ error, loading, data }} />
    }

    const fullUserStats = JSON.parse(data.getUserDeckStats) as FullUserStats

    console.log(fullUserStats)

    return (
        <ByUserStatsContainer>
            <BiographicalContainer></BiographicalContainer>
            <ReviewGeneChart {...fullUserStats} />
        </ByUserStatsContainer>
    )

    //const sortedReviews = _.takeRight(_.sortBy(raw.reviews, 'time'), 500)
}
//<p>{fullUserStats.user.displayName}</p>
//<p>{fullUserStats.user.email}</p>
//<p>{fullUserStats.user.id}</p>

const BiographicalContainer = styled.div`
    display: grid;
`

//{i > 0 && <p>{formatLeft(raw.lefts.find((l) => l.time === _.last(grouped[i])!.time))}</p>}

// const TwoWeekHistoryChart = ({ data }: { data: FullUserStats }) => {
//     const sortedReviews = _.sortBy(data.reviews, 'time')
//     const byDay = _.groupBy(sortedReviews, (x) => toDateStr(new Date(x.time * 1000)))
//     //_.takeWhile(sortedReviews, s => s.time < (Date.now() / 1000) )
// }

const ReviewGeneChart = ({ reviews, user }: FullUserStats) => {
    const [selectedID, setSelectedID] = useState<string | undefined>(undefined)
    const history = useHistory()

    const sortedReviews = _.sortBy(
        labelOutliers(reviews, (x) => x.totalTimeMS),
        'time'
    )

    let grouped: Record<number, Array<FullReviewInfo>> = {}
    const groupByDate = _.groupBy(sortedReviews, (x) => toUserSRSDay(newDate(x.reviewedAt), user))
    //console.log(groupByDate)
    grouped = groupByDate
    const currentSRSDay = toUserSRSDay(newDate(), user)

    //const startDate = currentSRSDay-21
    const startDate = 0

    return (
        <>
            {_.range(startDate, currentSRSDay + 1).map((i) =>
                grouped[i] ? (
                    <GroupContainer key={i}>
                        <ConsecutiveReviewBlock>
                            {grouped[i].map((r, i) => (
                                <ReviewRepresentation
                                    onClick={() => {
                                        window.open(`/view/card/${r.cardId}`)
                                        //history.push(`/view/card/${r.cardId}`)
                                    }}
                                    key={r.reviewedAt + '' + r.cardId + '' + r.beforeTimesSeen}
                                    review={r}
                                    isSelected={selectedID === r.cardId}
                                    onMouseOver={() => {
                                        setSelectedID(r.cardId)
                                        console.log(r)
                                    }}
                                    shouldColor={
                                        r.answer !== AnswerType.Remembered ||
                                        (_(sortedReviews)
                                            .filter((x) => x.cardId === r.cardId)
                                            .findLast((x) => x.reviewedAt < r.reviewedAt)?.answer || AnswerType.Remembered) !==
                                            AnswerType.Remembered
                                    }
                                ></ReviewRepresentation>
                            ))}
                        </ConsecutiveReviewBlock>
                    </GroupContainer>
                ) : (
                    <GroupContainer key={i}>
                        <ConsecutiveReviewBlock>
                            <ZeroDay />
                        </ConsecutiveReviewBlock>
                    </GroupContainer>
                )
            )}
        </>
    )
}

const ZeroDay = styled.div`
    width: 10px;
    height: 20px;
    margin: 3px;
    background-color: lightgrey;
`

const GroupContainer = styled.div`
    display: grid;
    grid-auto-flow: row;
`

const ConsecutiveReviewBlock = styled.div`
    display: grid;
    margin: 3px;
    grid-auto-flow: column;
    justify-content: start;
`

const FrontReviewBlock = styled.div.attrs((props: { color: string; width: number }) => ({
    style: {
        width: props.width + 'px',
    },
}))`
    height: 20px;
    background-clip: padding-box;
    display: grid;
    align-content: center;
    justify-content: center;
` as any

const BackReviewBlock = styled.div.attrs((props: { color: string; width: string }) => ({
    style: {
        backgroundColor: props.color,
        width: props.width + 'px',
    },
}))`
    height: 20px;
    background-clip: padding-box;
    display: grid;
    align-content: center;
    justify-content: center;
` as any

/*
Over 200 classes were generated for component styled.div. 
Consider using the attrs method, together with a style object for frequently changed styles.
Example:
  const Component = styled.div.attrs(props => ({
    style: {
      background: props.background,
    },
  }))`width: 100%;`

  <Component />
*/

const ReviewBlockContainer = styled.div.attrs(
    (props: { color: string; borderType: string; radius: string; opacity: number }) => ({
        style: {
            border: props.color + ' 2px ' + props.borderType,
            opacity: props.opacity,
            borderRadius: props.radius,
        },
    })
)`
    display: grid;
    grid-auto-flow: column;
    margin: 3px;
    cursor: pointer;
` as any

const ReviewRepresentation = ({
    review,
    isSelected,
    onMouseOver,
    shouldColor,
    onClick,
}: {
    review: FullReviewInfo & { outlier?: boolean }
    isSelected: boolean
    onMouseOver: any
    shouldColor: boolean
    onClick: (arg0: any) => void
}) => {
    const hue = hashStringToNumber(review.cardId) % 360
    const color = shouldColor ? `hsl(${hue}, 100%, ${isSelected ? '0%' : '50%'})` : `hsl(114, 0%, 80%)`

    const frontTime = review.frontTimeMS
    const backTime = review.totalTimeMS - review.frontTimeMS

    return (
        <ReviewBlockContainer
            onMouseOver={onMouseOver}
            color={color}
            radius={review.beforeTimesSeen === 0 ? '10px' : '0px'}
            borderType={review.answer === AnswerType.Remembered ? 'solid' : 'dashed'}
            opacity={isSelected ? 1 : 0.5}
            onClick={onClick}
        >
            {frontTime > REVIEW_TOO_LONG_TIME_MS ? (
                <FrontReviewBlock color={color} width={'100'}>
                    {dayjs.duration(frontTime, 'milliseconds').humanize()}
                </FrontReviewBlock>
            ) : (
                <FrontReviewBlock color={color} width={frontTime / 1000 + ''}></FrontReviewBlock>
            )}
            {backTime > REVIEW_TOO_LONG_TIME_MS ? (
                <BackReviewBlock color={color} width={'100'}>
                    {dayjs.duration(backTime, 'milliseconds').humanize()}
                </BackReviewBlock>
            ) : (
                <BackReviewBlock color={color} width={backTime / 1000}></BackReviewBlock>
            )}
        </ReviewBlockContainer>
    )
}

const MyResponsiveBar = ({ data /* see data tab */ }: any) => (
    <ResponsiveBar
        data={data}
        keys={[CardStatus.New, CardStatus.Memorized, CardStatus.Learning]}
        indexBy="country"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'nivo' }}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'country',
            legendPosition: 'middle',
            legendOffset: 32,
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'food',
            legendPosition: 'middle',
            legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    />
)
