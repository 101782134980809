import { useQuery } from '@apollo/client'
import { Paper } from '@material-ui/core'
import { ResponsiveLine } from '@nivo/line'
import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { GQLGetFullDeckStatsQuery } from '../generated/client-graphql-types'
import { GET_FULL_DECK_STATS } from '../Management/DeckManagement'
import { REVIEW_TOO_LONG_TIME_MS } from '../shared/sharedutil'
import { toGlobalSRSDay } from '../shared/srsDays'
import { fromUnix, newDate } from '../shared/times'
import { AnswerType, FullReviewInfo, FullUserStats, CardStatus, User } from '../shared/types'
import { DataNotLoaded } from '../Utils'
import { GET_ALL_USERS_FULL_STATS } from './MultiUserStats'

export const ReviewGraphMetrics = () => {}

const emailBlacklist = ['robertvcunningham@gmail.com', 'vroy101@gmail.com', 'vroomvsr@gmail.com']

export const useAllReviews = () => {
    const { error, loading, data } = useQuery(GET_ALL_USERS_FULL_STATS)

    if (error || loading || !data) {
        return { error, loading, data: null }
    }

    const parsed: FullUserStats[] = JSON.parse(data.getAllUserStats)

    const reviews = _(parsed)
        .filter((f) => !emailBlacklist.includes(f.user.email!))
        .flatMap((fus: FullUserStats) => fus.reviews.map((r) => ({ ...r, user: fus.user })))
        .value()

    return { error, loading, data: reviews }
}

export const useDeckReviews: (arg0: string) => ReturnType<typeof useAllReviews> = (deckID: string) => {
    const { data, loading, error } = useQuery<GQLGetFullDeckStatsQuery>(GET_FULL_DECK_STATS, {
        variables: { tagID: deckID },
    })

    if (!data || loading || error) {
        return { error, loading, data: null }
    }

    const parsed: (FullReviewInfo & { user: User })[] = JSON.parse(data.getFullDeckStats)
    console.log(parsed)

    /*
    const anonymousUser = (id: string): User => ({
        displayName: 'Anonymous User',
        id,
        email: 'anon@anon.com',
        picture: '',
        settings: {
            needsCommitmentScreen: false,
            needsDeckWelcome: false,
            rolloverSecond: 0,
            tzOffsetSeconds: 0,
        },
        createdTime: newDate().toDate(),
    })

    const reviews = _(parsed)
        .map((r: FullReviewInfo) => ({ ...r, user: anonymousUser(r.userId) }))
        .value()
    */
    const reviews = parsed

    return { error, loading, data: reviews }
}

type ReviewAggregationFunction = (arg0: FullReviewInfo[]) => number

const reviewsToChartData = (reviews: FullReviewInfo[], aggregationFunction: ReviewAggregationFunction) => {
    const byDay = _(reviews)
        .groupBy((r) => toGlobalSRSDay(newDate(r.reviewedAt), 0))
        .mapValues((v) => aggregationFunction(v))
        .value()

    const minDay = _(byDay)
        .keys()
        .map((k) => parseInt(k))
        .min()!

    const xyData = _(byDay)
        .toPairs()
        .map(([day, value]: [string, number]) => ({ x: parseInt(day) - minDay, y: value }))
        .sortBy('x')
        .value()

    return xyData
}

export const NicelyFormattedChart = ({ title, children }: { title: string; children: JSX.Element }) => {
    return (
        <ChartContainer>
            <NicelyFormattedTitle>{title}</NicelyFormattedTitle>
            {children}
        </ChartContainer>
    )
}

const NicelyFormattedTitle = styled.div`
    padding-top: 10px;
    color: grey;
    font-size: 20px;
`

export const GenericChartMetrics = ({
    aggregate,
    hookData,
    yAxisName,
}: {
    aggregate: ReviewAggregationFunction
    hookData: ReturnType<typeof useAllReviews>
    yAxisName: string
}) => {
    //const { error, loading, data: reviews } = useAllReviews()
    const { error, loading, data: reviews } = hookData

    if (error || loading || !reviews) {
        return <DataNotLoaded {...{ loading, error, data: reviews }} />
    }

    const data = reviewsToChartData(reviews, aggregate)

    return (
        <TopLineMetricsContainer>
            <DailyTimeLine
                yAxisName={yAxisName}
                xAxisName={'Days'}
                data={[
                    {
                        id: '',
                        color: 'red',
                        data: data,
                    },
                ]}
            ></DailyTimeLine>
        </TopLineMetricsContainer>
    )
}

const ChartContainer = styled(Paper)`
    display: grid;
    grid-gap: 10px;
    justify-items: center;
    width: 100%;
`

export const CohortRetentionMetrics = () => {
    const { error, loading, data: reviews } = useAllReviews()

    if (error || loading || !reviews) {
        return <DataNotLoaded {...{ loading, error, data: reviews }} />
    }

    const byStartWeek = _(reviews)
        .map((r) => ({ ...r, reviewedAt: newDate(newDate(r.reviewedAt).diff(newDate(r.user.createdTime))).toDate() }))
        .groupBy((r) => newDate(r.user.createdTime).startOf('week').toISOString()) // after: week -> [review]
        .mapValues((reviewsFromUsersStartingAtWeek) => reviewsToChartData(reviewsFromUsersStartingAtWeek, aggregateByTime))
        .toPairs()
        .map(([week, info], i) => ({ id: week, color: `hsl(${i * 10}, 70%, 50%)`, data: info }))
        .value()

    console.log(byStartWeek)

    return (
        <TopLineMetricsContainer>
            <DailyTimeLine data={byStartWeek}></DailyTimeLine>
        </TopLineMetricsContainer>
    )
}

export const TopLineMetrics = () => {
    const hookData = useAllReviews()
    return <GenericChartMetrics yAxisName="Hours" aggregate={aggregateByTime} hookData={hookData}></GenericChartMetrics>
}

export const aggregateByTime = (reviews: FullReviewInfo[]) =>
    //_.sum(reviews.map((r) => _.clamp(r.totalTimeMS, 0, REVIEW_TOO_LONG_TIME_MS)))
    _.sum(reviews.filter((r) => r.totalTimeMS < REVIEW_TOO_LONG_TIME_MS).map((r) => r.totalTimeMS / 1000 / 60 / 60))

export const aggregateByCount = (reviews: FullReviewInfo[]) => reviews.length

export const TotalWrongCards = () => {
    const hookData = useAllReviews()
    return (
        <GenericChartMetrics
            hookData={hookData}
            aggregate={(reviews: FullReviewInfo[]) => reviews.filter((r) => r.answer === AnswerType.Forgot).length}
            yAxisName="Wrong Cards"
        ></GenericChartMetrics>
    )
}

const TopLineMetricsContainer = styled.div`
    display: grid;
    align-content: center;
    justify-content: center;
`

const ResponsiveLineContainer = styled.div`
    height: 400px;
    max-height: 50vh;
    //width: 400px;
    width: 40vw;
    //max-width: 40vw;
    @media (max-width: 481px) {
        max-width: 95vw;
    }
    //width: 500px;
    //max-width: 95vw;
    //min-width: 40vw;
`

const DailyTimeLine = ({ data, yAxisName, xAxisName }: { data: any; xAxisName?: string; yAxisName?: string }) => (
    <ResponsiveLineContainer>
        <ResponsiveLine
            data={data}
            margin={{ top: 20, right: 20, bottom: 40, left: 60 }}
            yFormat=">-1f"
            xScale={{ type: 'linear' }}
            yScale={{ type: 'linear' }}
            curve="linear"
            colors={{ scheme: 'spectral' }}
            lineWidth={2}
            enableArea={true}
            areaOpacity={0.15}
            enableGridX={false}
            enablePoints={false}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: yAxisName,
                legendOffset: -40,
                legendPosition: 'middle',
            }}
        />
    </ResponsiveLineContainer>
)
