import { gql } from '@apollo/client'

export const ALL_CARD_FIELDS_FRAGMENT = gql`
    fragment allCardFields on Card {
        id
        type
        status
        interval
        due
        index
        timesSeen

        source

        comments {
            author {
                displayName
                picture
                id
            }
            contents
            likes
            timeCreated
        }
    }
`
