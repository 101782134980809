import { gql, useQuery } from '@apollo/client'
import { Badge, Grow, IconButton, LinearProgress, makeStyles, Tooltip, Zoom } from '@material-ui/core'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import FastForwardIcon from '@material-ui/icons/FastForwardRounded'
import LinkIcon from '@material-ui/icons/Link'
import UpdateIcon from '@material-ui/icons/Update'
import copy from 'copy-to-clipboard'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { ClientCard } from '../client_types'
import { GQLGetProgressQuery } from '../generated/client-graphql-types'
import { UserContext } from '../Login'
import { makeInterleaver } from '../shared/interleaver'
import { frontendURL, leftToReview } from '../shared/sharedutil'
import { AnswerType, CardStatus, TagID } from '../shared/types'
import { LiveCardMeta } from '../types'
import { useAddMoreCards, useSubmitReview } from './Card'

export const ReviewerMeta = ({
    cards,
    activeCard,
    liveCardMeta,
}: {
    cards: ClientCard[]
    activeCard: ClientCard
    liveCardMeta: LiveCardMeta
}) => {
    const user = useContext(UserContext)
    const { deckID } = useParams<{ deckID: string }>()
    const { data, refetch } = useQuery<GQLGetProgressQuery>(GET_PROGRESS, { variables: { tagID: deckID } })

    useEffect(() => {
        //if (activeCard.id && activeCard.id[0] >= '0' && activeCard.id[0] <= '9') {
        refetch()
        //}
    }, [activeCard.id])

    if (!user) {
        return <>Not logged in</>
    }

    const { remaining, newLeft, learningLeft, memorizedLeft, introLeft } = leftToReview(
        cards,
        makeInterleaver(3),
        user.settings.rolloverSecond
    )

    const isFamiliar = liveCardMeta.intervals.remembered === '10 years'

    return (
        <>
            <ReviewerMetaInternal>
                <RemainingCardWidget {...{ activeCard, newLeft, learningLeft, memorizedLeft }}></RemainingCardWidget>
                <ActionButtons {...{ activeCard, deckID }}></ActionButtons>
                {isFamiliar ? <FamiliarIntervalWidget /> : <IntervalWidget {...{ liveCardMeta }}></IntervalWidget>}
            </ReviewerMetaInternal>
            <LinearProgress
                color="secondary"
                variant="determinate"
                value={(data?.getTagByID?.progress || 0) * 100}
            ></LinearProgress>
        </>
    )
}

const ReviewerMetaExternal = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    grid-gap: 10px;
`

const ReviewerMetaInternal = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: auto 1fr auto;
    justify-items: space-between;
    width: 100%;
    padding: 5px 15px;
    text-align: center;
    user-select: none;
`

export const FamiliarIntervalWidget = ({ inline }: { inline?: boolean }) => (
    <Tooltip
        title={`We think you already know this card. If you mark it correct, we'll never show it to you again.`}
        placement="top"
        TransitionComponent={Zoom}
        arrow
    >
        <FamiliarIntervalContainer {...{ inline }}>
            <UpdateIcon style={{ color: 'hsl(217, 85%, 60%)', fontSize: '16pt' }} />
            <p>Forever</p>
        </FamiliarIntervalContainer>
    </Tooltip>
)

const IntervalWidget = ({ liveCardMeta }: { liveCardMeta: LiveCardMeta }) => (
    <Tooltip
        title={`This card's interval is ${liveCardMeta.intervals.remembered}. That means that if you get it right, we'll show it to you next ${liveCardMeta.intervals.remembered} from now.`}
        placement="top"
        TransitionComponent={Zoom}
        arrow
    >
        <IntervalContainer>{liveCardMeta.intervals.remembered}</IntervalContainer>
    </Tooltip>
)

const IntervalContainer = styled.div`
    display: inline-grid;
    justify-content: center;
    align-content: center;
    align-items: center;
    grid-auto-flow: column;
    grid-gap: 5px;
`

const FamiliarIntervalContainer = styled(IntervalContainer)`
    color: hsl(217, 85%, 60%);
    p {
        padding: 0 0 2px 0;
        font-weight: 500;
    }
`

const RemainingCardWidget = ({
    activeCard,
    newLeft,
    learningLeft,
    memorizedLeft,
}: {
    activeCard: ClientCard
    newLeft: number
    learningLeft: number
    memorizedLeft: number
}) => {
    return (
        <RemainingCardWidgetContainer>
            <Tooltip
                TransitionComponent={Zoom}
                title={`You have ${newLeft} new cards left today. A card is considered "new" if you've never seen it before.`}
                placement="top"
                arrow
            >
                <div style={activeCard.status === CardStatus.New ? { fontWeight: 600 } : { fontWeight: 300 }}>{newLeft}</div>
            </Tooltip>
            <Tooltip
                TransitionComponent={Zoom}
                title={`You have ${learningLeft} learning cards left today. A card is considered "learning" if you recently got it wrong.`}
                placement="top"
                arrow
            >
                <div style={activeCard.status === CardStatus.Learning ? { fontWeight: 600 } : { fontWeight: 300 }}>
                    {learningLeft}
                </div>
            </Tooltip>
            <Tooltip
                TransitionComponent={Zoom}
                title={`You have ${memorizedLeft} memorized cards left today. A card is considered "memorized" if you learned it a while ago and haven't gotten it wrong since.`}
                placement="top"
                arrow
            >
                <div style={activeCard.status === CardStatus.Memorized ? { fontWeight: 600 } : { fontWeight: 300 }}>
                    {memorizedLeft}
                </div>
            </Tooltip>
        </RemainingCardWidgetContainer>
    )
}

const RemainingCardWidgetContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: space-between;
    align-items: center;
    grid-gap: 5px;
`

const GET_PROGRESS = gql`
    query getProgress($tagID: ID!) {
        getTagByID(tagID: $tagID) {
            id
            progress
        }
    }
`

/*
const FLAG_CARD = gql`
    mutation flagCard($cardId: String, $flagged: Boolean) {
        flagCard(cardId: $cardId, flagged: $flagged) {
            id
            flagged
        }
    }
`
*/

const ActionButtons = ({ activeCard, deckID }: { activeCard: ClientCard; deckID: TagID }) => {
    //const [flagMutation] = useMutation<GQLFlagCardMutation>(FLAG_CARD)
    const submitReview = useSubmitReview()
    const addMore = useAddMoreCards(deckID, 1)
    return (
        <>
            <ActionButtonsContainer>
                <ActionButton
                    Icon={LinkIcon}
                    tip="Copy a link to this card."
                    onClick={() => {
                        copy(`${frontendURL}/view/card/${activeCard.id}`)
                    }}
                    active={false}
                    snackbarMessage={'Link copied to your clipboard.'}
                ></ActionButton>
                <ActionButton
                    Icon={FastForwardIcon}
                    tip="Skip & suspend this card. Useful if you know it and never want to see it again. (Hotkey S)"
                    onClick={async () => {
                        await submitReview(activeCard.id, AnswerType.Suspended, 0, 0, activeCard, {})
                        if (activeCard.status === CardStatus.New) await addMore()
                    }}
                    active={true}
                    snackbarMessage={'Card suspended.'}
                    hotkey={'KeyS'}
                    badge={true}
                ></ActionButton>
            </ActionButtonsContainer>
        </>
    )
}

// <ActionButton
//     Icon={FlagIcon}
//     tip="Click here to flag this card. This indicates to the creator of the deck that they should review this card"
//     onClick={() => {
//         flagMutation({
//             variables: {
//                 flagged: true,
//                 cardId: activeCard.id,
//             },
//         })
//     }}
//     active={false}
//     snackbarMessage={'This card has been flagged for review.'}
// ></ActionButton>

declare global {
    interface Window {
        custom_hotkeys: { code: string; fn: () => void }[] | undefined
    }
}

export const useCustomHotkey = (hotkeyCode: string, fn: () => void) => {
    useEffect(() => {
        if (hotkeyCode) {
            window.custom_hotkeys = window.custom_hotkeys || []
            window.custom_hotkeys.push({ code: hotkeyCode, fn })
        }

        return () => {
            window.custom_hotkeys = window.custom_hotkeys || []
            window.custom_hotkeys = window.custom_hotkeys.filter((h) => h.code !== hotkeyCode)
        }
    })
}

const ActiveIconButton = styled(IconButton)`
    @keyframes pulseshadow {
        from {
            opacity: 0%;
        }

        to {
            opacity: 100%;
        }
    }

    &:after {
        content: '';
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 20px 0px blue;
        border-radius: 50%;

        animation-duration: 1.5s;
        animation-name: pulseshadow;
        animation-iteration-count: infinite;
        animation-direction: alternate;
    }
`

const RedBadge = styled(Badge)`
    & .MuiBadge-badge {
        color: #fff;
        background: #ff4d4f;
    }
`

const ActionButton = ({
    onClick,
    Icon,
    tip,
    active,
    snackbarMessage,
    hotkey,
    badge,
}: {
    onClick: () => void
    Icon: any
    tip: string
    active: boolean
    snackbarMessage: string
    hotkey?: string
    badge?: boolean
}) => {
    const { enqueueSnackbar } = useSnackbar()

    const handleAction = () => {
        enqueueSnackbar(snackbarMessage, {
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            autoHideDuration: 1000,
            /*@ts-ignore*/
            TransitionComponent: Grow,
        })
        onClick()
    }

    useCustomHotkey(hotkey || '', handleAction)

    //<IconButton size="small" color="inherit" onClick={handleAction} className={active ? classes.root : undefined}>
    return (
        <Tooltip TransitionComponent={Zoom} title={tip} placement="top" arrow enterDelay={300} leaveDelay={500}>
            <RedBadge
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                color="secondary"
                variant="dot"
                overlap="circle"
                invisible={!badge}
            >
                <IconButton size="small" color="inherit" onClick={handleAction}>
                    <Icon style={{ opacity: 0.54 }} />
                </IconButton>
            </RedBadge>
        </Tooltip>
    )
}

const ActionButtonsContainer = styled.div`
    display: grid;
    justify-content: center;
    align-content: center;
    grid-auto-flow: column;
    grid-gap: 20px;
`
