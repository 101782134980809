import styled from "styled-components";

export const ReviewerContainer = styled.div`
    margin: 15px 0px;
    @media (max-width: 1000px) {
        margin: 0px 0px;
    }
    display: grid;
    justify-items: center;
    grid-gap: 15px;
    grid-template-rows: 1fr;
`
