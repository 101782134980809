import { Button, ListItemText, List, ListItem, ListItemAvatar, Avatar, Card } from '@material-ui/core'
import _ from 'lodash'
import {
    ChessPawn,
    ChessRook,
    ChessKing,
    Cancel,
    CheckboxMarkedCircle,
    IdeogramCjk,
    FormatListNumbered,
    Certificate,
} from 'mdi-material-ui'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { CommitmentSelectorInternal, useMutateCourseSettings } from '../DeckPreview/DeckSettings'
import { doLogin } from '../Login'
import { TagID } from '../shared/types'
import { EndOfDeckCard } from './Card'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import { People, PhoneIphone, School, Face } from '@material-ui/icons'
import { gql, useMutation } from '@apollo/client'
import { PreviouslyStudied } from '../shared/sharedutil'
import { FamiliarIntervalWidget } from './ReviewerMeta'
import { DailyTimeSelector } from '../DeckSettings/NewCards'

enum CommitmentStage {
    TheDeal,
    ChooseDifficulty,
    Email,
    LoveMe,
    Done,
}

export const CommitmentSelectionScreen = ({ done, deckID }: { done: Function; deckID: TagID }) => {
    const [stage, setStage] = useState<CommitmentStage>(CommitmentStage.TheDeal)
    const [emailChoice, setEmailChoice] = useState<boolean | undefined>()
    const [difficultyChoice, setDifficultyChoice] = useState<number | undefined>()

    if (stage === CommitmentStage.TheDeal) {
        return (
            <InstructionsContainer>
                <InstructionsText>
                    <InstructionParagraph>
                        <b>Congratulations!</b> You've finished your first day of reviews.
                    </InstructionParagraph>
                    <InstructionParagraph>
                        Quantized works best if you review your cards every day. Your memory doesn’t take breaks. Every day that
                        you skip Quantized, you’ll forget some cards that you used to know.
                    </InstructionParagraph>
                    <InstructionParagraph>
                        So here’s the deal. Commit to Quantized every day, and you’ll trade 10 to 30 minutes a day for the ability
                        to recall anything you want over long time horizons: superhuman memory.
                    </InstructionParagraph>
                    <InstructionParagraph>
                        But skipping lots of days leads to awful, gruelling sessions where you slog through a backlog of hundreds
                        of cards that you can’t remember anymore and have to relearn, only to re-forget them by the next session.
                    </InstructionParagraph>
                    <InstructionParagraph>
                        This tradeoff isn’t for everyone, but it’s been life-changing for me, and I hope it can be for you as
                        well. Good luck on your journey.
                    </InstructionParagraph>
                    <InstructionParagraph>Robert</InstructionParagraph>
                </InstructionsText>
                <Button onClick={() => setStage(CommitmentStage.ChooseDifficulty)} variant="outlined">
                    Next
                </Button>
            </InstructionsContainer>
        )
    } else if (stage === CommitmentStage.ChooseDifficulty) {
        return (
            <InstructionsContainer>
                <InstructionsTitle>Choose a difficulty</InstructionsTitle>
                <DailyTimeSelector
                    deckID={deckID}
                    //onDone={({ minutes }: { minutes: number }) => {
                    //    setDifficultyChoice(minutes)
                    //    setStage(minutes !== 0 ? CommitmentStage.Email : CommitmentStage.LoveMe)
                    //}}
                />
                <Button onClick={() => setStage(CommitmentStage.Email)}>Done</Button>
            </InstructionsContainer>
        )
    } else if (stage === CommitmentStage.Email) {
        return (
            <InstructionsContainer>
                <InstructionsTitle>Want help?</InstructionsTitle>
                <InstructionsText>
                    <InstructionParagraph>
                        The first week of SRS was really awful for me, and I actually almost gave up on the fourth day. Luckily,
                        Quantized is small enough that I (Robert) can handwrite you a motivational email if you fall behind in the
                        first week. :)
                    </InstructionParagraph>
                </InstructionsText>
                <EmailSelector
                    onSelect={(v: boolean) => {
                        setEmailChoice(v)
                        setStage(CommitmentStage.LoveMe)
                    }}
                />
            </InstructionsContainer>
        )
    } else if (stage === CommitmentStage.LoveMe) {
        return (
            <InstructionsContainer>
                <InstructionsText>
                    <InstructionParagraph>
                        Send me an email if you run into anything that's broken, or if you just want to chat SRS! I love hearing
                        from you guys. You can hit me up whenever at polysift@gmail.com, or just click the mail icon in the top
                        right corner.
                    </InstructionParagraph>
                </InstructionsText>
                <Button
                    onClick={() => {
                        setStage(CommitmentStage.Done)
                        done(emailChoice, difficultyChoice)
                    }}
                    variant="outlined"
                >
                    Sure Mom, whatever
                </Button>
            </InstructionsContainer>
        )
    } else if (stage === CommitmentStage.Done) {
        return <EndOfDeckCard />
    } else {
        return <>Wtf</>
    }
}

const EmailSelector = ({ onSelect }: { onSelect: (response: boolean) => void }) => {
    const options = [
        {
            value: true,
            name: 'Yes',
            text: 'Make me feel extremely guilty if I miss a day this week',
            icon: <CheckboxMarkedCircle />,
        },
        { value: false, name: 'No', text: 'Miss me with that, yo.', icon: <Cancel /> },
    ]

    return (
        <CommitmentSelectorInternal>
            <List>
                {options.map((o) => (
                    <ListItem key={o.name} button onClick={() => onSelect(o.value)}>
                        <ListItemAvatar>
                            <Avatar>{o.icon}</Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={o.name} secondary={o.text} />
                    </ListItem>
                ))}
            </List>
        </CommitmentSelectorInternal>
    )
}

export const InstructionsContainer = styled.div`
    margin: auto auto;
    display: grid;
    max-width: 95vw;
    width: 1000px;
    justify-content: center;

    button {
        margin: auto;
    }
`

export const InstructionsTitle = styled.div`
    font-size: 6vh;
    font-weight: 100;
    margin: auto;
`

export const InstructionsText = styled.div`
    font-size: 2.3vh;
    font-weight: 300;
    opacity: 70%;
`

const InstructionParagraph = styled.div`
    margin: 20px 0;
`

export const WelcomeReviewer = ({ done }: { done: (a0: any) => void }) => {
    return (
        <InstructionsContainer>
            <InstructionsText>
                <InstructionParagraph>
                    <b>Welcome.</b> Quantized is a next-generation flashcard program, designed to make learning and retaining
                    skills efficient.
                </InstructionParagraph>
                <InstructionParagraph>
                    Quantized is based on spaced repetition. When you get cards right, Quantized won’t show them to you for longer
                    periods of time. When you get cards wrong, Quantized will show them to you more often. That way, you see cards
                    just often enough to remember them.
                </InstructionParagraph>
            </InstructionsText>

            <Button onClick={done} variant="outlined">
                Next
            </Button>
        </InstructionsContainer>
    )
}

/*
const UPDATE_COURSE_METADATA = gql`
mutation updateCourseMetadata($courseId: ID!, $update: String!) {
    updateCourseMetadata(courseId: $courseId, update: $update) {
        success
    }
}
`
*/
const iconStyle = { fontSize: 60, color: 'grey' }

export const ExperienceSelector = ({ deckID }: { deckID: string }) => {
    const mutateCourseSettings = useMutateCourseSettings(deckID)

    const [selected, setSelected] = useState<PreviouslyStudied[]>([])
    const [hasExperience, setHasExperience] = useState<boolean | undefined>(undefined)

    const icons: Record<string, JSX.Element> = {
        book: <MenuBookIcon style={iconStyle}></MenuBookIcon>,
        kanji: <IdeogramCjk style={iconStyle}></IdeogramCjk>,
        list: <FormatListNumbered style={iconStyle}></FormatListNumbered>,
        class: <People style={iconStyle}></People>,
        app: <PhoneIphone style={iconStyle}></PhoneIphone>,
        test: <Certificate style={iconStyle}></Certificate>,
    }

    const experiences = [
        { id: PreviouslyStudied.RTK, name: 'RTK', icon: 'kanji' },
        { id: PreviouslyStudied.Core2k, name: 'Core 2k', icon: 'list' },
        { id: PreviouslyStudied.Core6k, name: 'Core 6k', icon: 'list' },
        { id: PreviouslyStudied.Genki1, name: 'Genki 1', icon: 'book' },
        { id: PreviouslyStudied.Genki2, name: 'Genki 2', icon: 'book' },
        { id: PreviouslyStudied.TangoN5, name: 'Tango N5', icon: 'book' },
        { id: PreviouslyStudied.TangoN4, name: 'Tango N4', icon: 'book' },
        { id: PreviouslyStudied.Semesters2, name: '1-2 Semesters', icon: 'class' },
        { id: PreviouslyStudied.Semesters4, name: '3-4 Semesters', icon: 'class' },
        { id: PreviouslyStudied.WaniKani, name: 'WaniKani', icon: 'kanji' },
        { id: PreviouslyStudied.DuoLingo, name: 'DuoLingo', icon: 'app' },
        { id: PreviouslyStudied.JLPTN5, name: 'Passed N5', icon: 'test' },
        { id: PreviouslyStudied.JLPTN4, name: 'Passed N4', icon: 'test' },
        { id: PreviouslyStudied.JLPTN3, name: 'Passed N3', icon: 'test' },
    ]

    const experienceExplainer = <ExperienceExplainer></ExperienceExplainer>

    const experienceSelector = (
        <>
            <ExperienceTitle>Let's get an idea of your prior experience</ExperienceTitle>
            <ExperienceSubtitle>Select anything that you've done more than half of.</ExperienceSubtitle>
            <ExperienceTileContainer>
                {experiences.map((e) =>
                    selected.includes(e.id) ? (
                        <SelectedExperienceTile
                            key={e.name}
                            onClick={() => {
                                setSelected(_.without(selected, e.id))
                            }}
                        >
                            <CheckCircleOutlineIcon style={{ fontSize: 60, color: '#23c06d' }}></CheckCircleOutlineIcon>
                            <p>{e.name}</p>
                        </SelectedExperienceTile>
                    ) : (
                        <UnselectedExperienceTile
                            key={e.name}
                            onClick={() => {
                                setSelected(_.concat(selected, [e.id]))
                            }}
                        >
                            {e.icon && icons[e.icon]}
                            <p>{e.name}</p>
                        </UnselectedExperienceTile>
                    )
                )}
            </ExperienceTileContainer>
        </>
    )

    const brandNew = (
        <>
            <ExperienceTitle>Welcome!</ExperienceTitle>
            <ExperienceSubtitle>
                Great! Japanese Foundation starts with content easy enough for people who know only Hiragana. We're really excited
                for you to start your Japanese learning journey!
            </ExperienceSubtitle>
        </>
    )

    return (
        <ExperienceContainer>
            <HasExperience {...{ hasExperience, setHasExperience }}></HasExperience>
            {hasExperience === true && experienceSelector}
            {hasExperience === false && brandNew}
            {hasExperience === true && experienceExplainer}
            {(hasExperience === false || (hasExperience === true && selected.length > 0)) && (
                <Button
                    variant="outlined"
                    onClick={() => {
                        mutateCourseSettings({ custom: { previouslyStudied: selected } })
                    }}
                >
                    Let's go
                </Button>
            )}
        </ExperienceContainer>
    )
}

const HasExperience = ({
    hasExperience,
    setHasExperience,
}: {
    hasExperience: boolean | undefined
    setHasExperience: (arg0: boolean) => void
}) => {
    const options = [
        { name: "I'm brand new!", value: false, icon: <Face style={iconStyle}></Face> },
        { name: "I'm experienced", value: true, icon: <School style={iconStyle}></School> },
    ]

    return (
        <>
            <ExperienceTitle>Have you learned any Japanese before?</ExperienceTitle>
            <ExperienceTileContainer>
                {options.map((e) =>
                    hasExperience === e.value ? (
                        <SelectedExperienceTile
                            key={e.name}
                            onClick={() => {
                                setHasExperience(e.value)
                            }}
                        >
                            <CheckCircleOutlineIcon style={{ fontSize: 60, color: '#23c06d' }}></CheckCircleOutlineIcon>
                            <p>{e.name}</p>
                        </SelectedExperienceTile>
                    ) : (
                        <UnselectedExperienceTile
                            key={e.name}
                            onClick={() => {
                                setHasExperience(e.value)
                            }}
                        >
                            {e.icon}
                            <p>{e.name}</p>
                        </UnselectedExperienceTile>
                    )
                )}
            </ExperienceTileContainer>
        </>
    )
}

const ExperienceExplainer = () => (
    <ExperienceSubtitle>
        Cards with material you've seen before are called <em>famililar</em>, and will show an interval of{' '}
        <FamiliarIntervalWidget /> when you first see them. If you mark a familiar card correct, you'll never see it again. If you
        mark it incorrect, you can continue studying it like normal. That way, you can cruise through stuff you already know,
        without skipping over stuff that might be new.
    </ExperienceSubtitle>
)

/*
const ExperienceExplainer = () => (
    <ExperienceSubtitle>
        Instead of skipping cards covered by your previous learning outright, we <em>boost</em> them. That means that:
        <ul>
            <li>You'll see every card at least once.</li>
            <li>If the card is covered by your previous learning and you get it right when you first see it, you'll never see it again.</li>
            <li>If you've forgotten a card or want more practice with it, you can mark it "forgot" and it'll be scheduled like normal.</li>
        </ul>
        That way, you can fly through the material you already know, without skipping over stuff that might be new.
    </ExperienceSubtitle>
)
*/

const ExperienceContainer = styled.div`
    display: grid;
    margin: auto auto;
    max-width: 95vw;
    width: 1000px;
    justify-content: center;
    grid-gap: 20px;
    padding: 30px 0px;

    button {
        margin: auto;
    }
`

const ExperienceTitle = styled.div`
    font-size: 4vh;
    font-weight: 300;
    margin: auto;
    text-align: center;
`

const ExperienceSubtitle = styled(ExperienceTitle)`
    opacity: 70%;
    font-size: 2vh;
`

const ExperienceTileContainer = styled.div`
    display: grid;
    grid-gap: 10px;
    width: calc(5 * (150px + 10px));
    max-width: 95vw;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    justify-items: center;
    justify-content: center;
    align-items: center;
    margin: auto;
`

const UnselectedExperienceTile = styled(Card)`
    display: grid;
    width: 150px;
    height: 150px;
    border-radius: 20px;
    justify-content: center;
    align-content: center;
    grid-gap: 10px;
    justify-items: center;
    cursor: pointer;

    p {
        text-align: center;
        font-family: Roboto;
        font-weight: 500;
        opacity: 87%;
    }
`

const SelectedExperienceTile = styled(UnselectedExperienceTile)`
    background-color: #e9faf3;
    border: 2px solid #23c06d;
`

export const DoneTryItScreen = () => {
    const history = useHistory()
    return (
        <InstructionsContainer>
            <InstructionsText>
                <InstructionParagraph>
                    You've finished the preview cards for this deck! To study the full deck with spaced repetition, we need you to
                    sign in with Google. This allows us to track which cards you get right over time, which is the whole point of
                    SRS!
                </InstructionParagraph>
                <InstructionParagraph>Once you sign in, click "Add deck" to add this deck to your account.</InstructionParagraph>
            </InstructionsText>
            <Button onClick={doLogin(history.location.pathname.replace('try', 'preview'))} variant="outlined">
                Sign up with Google
            </Button>
        </InstructionsContainer>
    )
}
