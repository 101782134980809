import { gql, useQuery } from '@apollo/client'
import React from 'react'
import styled from 'styled-components'
import { GQLGetPerformanceQuery } from '../generated/client-graphql-types'
import { DataNotLoaded } from '../Utils'

const GET_PERFORMANCE_QUERY = gql`
    query getPerformance {
        getPerformance
    }
`

const TimingDashboardContainer = styled.div`
    display: grid;
`

export const TopLevelTimingDashboard = () => {
    const { error, loading, data } = useQuery<GQLGetPerformanceQuery>(GET_PERFORMANCE_QUERY)

    if (error || loading || !data) {
        return <DataNotLoaded {...{ loading, error, data }} />
    }
    return <TimingDashboardContainer>{JSON.stringify(data.getPerformance)}</TimingDashboardContainer>
}
