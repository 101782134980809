import { useQuery } from '@apollo/client'
import _ from 'lodash'
import BookOpenPageVariant from 'mdi-material-ui/BookOpenPageVariant'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ClientDeck } from './client_types'
import { DeckInternal, DeckItemPresenter } from './DeckItem'
import { ALL_DECKS, DeckHolder } from './DiscoverPage'
import { GQLAllDecksQuery } from './generated/client-graphql-types'
import { DataNotLoaded, LargeMessage } from './Utils'

/*
const EmptyPlaceholder = (message: any) => {
    return (
        <EmptyPlaceholderContainer>
            <LargeIconContainer>
                <BookOpenPageVariant fontSize={"inherit"} opacity={.54} />
            </LargeIconContainer>
            You haven't added any decks yet! Add decks from the Discover tab, then come back here to study them.
        </EmptyPlaceholderContainer>
    )
}
 */

export const Study = () => {
    const { loading, error, data, refetch } = useQuery<GQLAllDecksQuery>(ALL_DECKS)

    const history = useHistory()

    useEffect(() => {
        refetch()
    }, [refetch])

    if (loading || error || !data) {
        return <DataNotLoaded {...{ loading, error, data }} />
    }

    const allDecks = data.allDecks as ClientDeck[]

    const deckList = _.orderBy(allDecks, (deck) => deck.tag.pendingReviewCount, 'desc').filter((x) => x.learning)

    if (deckList.length === 0) {
        return (
            <LargeMessage
                message={"You haven't added any decks yet! Add decks from the Discover tab, then come back here to study them."}
                icon={BookOpenPageVariant}
            />
        )
    }

    return (
        <DeckHolder>
            {deckList.map((deck) => (
                <DeckItemPresenter
                    key={deck.title}
                    {...deck}
                    onClick={() => {
                        history.push(`/preview/${deck.id}`)
                        refetch()
                    }}
                    mini_left={deck.tag.pendingReviewCount ? `${deck.tag.pendingReviewCount} left today` : 'Done for today'}
                    mini_right={`${_.round((deck as any).tag.progress * 100)}%`}
                /> //should probably refetch.
            ))}
            {_.range(10).map((num) => (
                <DeckInternal key={num} />
            ))}
        </DeckHolder>
    )
}
