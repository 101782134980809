import { gql, useQuery } from '@apollo/client'
import { Button } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { GQLAllUsersQuery } from '../generated/client-graphql-types'
import { User } from '../shared/types'
import { DataNotLoaded } from '../Utils'

export const ALL_USERS = gql`
    query allUsers {
        allUsers {
            displayName
            id
            email
        }
    }
`

export const BrowseUsers = () => {
    const { error, loading, data } = useQuery<GQLAllUsersQuery>(ALL_USERS)
    const history = useHistory()

    if (error || loading || !data) {
        return <DataNotLoaded {...{ loading, error, data }} />
    }

    const users = data.allUsers as Pick<User, 'id' | 'displayName'>[]

    return (
        <UserSelectionContainer>
            {users.map((u) => (
                <Button key={u.id} onClick={() => history.push(`/usage/${u.id}`)} variant="contained" color="primary">
                    {u.displayName}
                </Button>
            ))}
        </UserSelectionContainer>
    )
}

export const UserSelectionContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: center;
    align-content: center;
    margin: auto;
    grid-gap: 10px;
`
