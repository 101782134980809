import { useQuery } from '@apollo/client'
import { IconButton, Tab, Tabs, Tooltip } from '@material-ui/core'
import gql from 'graphql-tag'
import _ from 'lodash'
import React from 'react'
import { Link, Redirect, Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import {
    aggregateByCount,
    aggregateByTime,
    GenericChartMetrics,
    NicelyFormattedChart,
    useDeckReviews,
} from '../Admin/TopLineMetrics'
import { GQLGetFullDeckStatsQuery } from '../generated/client-graphql-types'
import { DataNotLoaded, LargeMessage } from '../Utils'
import CodeTags from 'mdi-material-ui/ClockOutline'
import DNAIcon from 'mdi-material-ui/Dna'
import MaterialTable from 'material-table'
import { TopLevelIndividualUserStats } from '../Admin/IndividualUserStats'

export const TopLevelDeckManager = () => {
    return (
        <DeckManagementContainer>
            <TabSelector></TabSelector>
            <Switch>
                <Redirect from="/deck/:deckID/manage" to="/deck/:deckID/manage/home" exact></Redirect>
                <Redirect from="/deck/:deckID/manage/" to="/deck/:deckID/manage/home" exact></Redirect>
                <Route path="/deck/:deckID/manage/home" exact component={DeckManagementHome} />
                <Route path="/deck/:deckID/manage/analytics" exact component={DeckManagementAnalytics} />
                <Route path="/deck/:deckID/manage/users" exact component={DeckManagementUsers} />
                <Route path="/deck/:tagID/manage/users/:userID/gene" exact component={TopLevelIndividualUserStats} />
                <Route path="/deck/:deckID/manage/settings" exact component={DeckManagementSettings} />
            </Switch>
        </DeckManagementContainer>
    )
}

const DeckManagementContainer = styled.div`
    display: grid;
    width: 100%;
    grid-auto-flow: column;
    grid-template-rows: auto 1fr;
`

export const GET_FULL_DECK_STATS = gql`
    query getFullDeckStats($tagID: ID!) {
        getFullDeckStats(tagID: $tagID)
    }
`

const DeckManagementAnalytics = () => {
    const { deckID } = useParams<{ deckID: string }>()

    const hookData = useDeckReviews(deckID)
    console.log(hookData.data)
    return (
        <AnalyticsPageContainer>
            <NicelyFormattedChart title="Total time on platform">
                <GenericChartMetrics yAxisName="Hours" aggregate={aggregateByTime} hookData={hookData}></GenericChartMetrics>
            </NicelyFormattedChart>
            <NicelyFormattedChart title="Total reviews on platform">
                <GenericChartMetrics yAxisName="Reviews" aggregate={aggregateByCount} hookData={hookData}></GenericChartMetrics>
            </NicelyFormattedChart>
        </AnalyticsPageContainer>
    )
}

const AnalyticsPageContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 481px) {
        grid-template-columns: 1fr;
    }
    align-items: start;
    justify-items: start;
    grid-gap: 10px;
    margin: 10px;
`

const DeckManagementUsers = () => {
    const { deckID } = useParams<{ deckID: string }>()

    const { error, loading, data } = useDeckReviews(deckID)
    if (!data || loading || error) {
        return <DataNotLoaded {...{ error, loading, data }}></DataNotLoaded>
    }

    const allUsers = _.groupBy(data, (r) => r.user.id)

    return (
        <ManagementPageContainer>
            <MaterialTable
                options={{
                    showTitle: false,
                    search: false,
                    padding: 'dense',
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    pageSizeOptions: [],
                }}
                localization={{
                    header: { actions: '' },
                }}
                components={{
                    Toolbar: () => <div></div>,
                }}
                columns={[
                    { title: 'Email', field: 'email' },
                    { title: 'Total Reviews', field: 'totalReviews' },
                    { title: 'Date Started', field: 'first' },
                    { title: 'Most Recent Review', field: 'last' },
                ]}
                data={Object.keys(allUsers).map((u) => ({
                    id: u,
                    email: allUsers[u][0].user.email,
                    totalReviews: allUsers[u].length,
                    first: allUsers[u][0].reviewedAt,
                    last: _.last(allUsers[u])?.reviewedAt,
                }))}
                actions={[
                    {
                        icon: () => <DNAIcon></DNAIcon>,
                        tooltip: "Open this user's gene chart",
                        onClick: (event, rowData) => {
                            console.log(rowData)
                            //@ts-ignore
                            const url = `/deck/${deckID}/manage/users/${rowData.id as string}/gene`
                            window.open(url, '_blank')
                        },
                    },
                ]}
            ></MaterialTable>
        </ManagementPageContainer>
    )

    return (
        <UserRowContainer>
            {Object.keys(allUsers).map((u) => (
                <UserRow key={u}>
                    <UserRowStat>{u}</UserRowStat>
                    <UserRowStat>{allUsers[u].length} total reviews</UserRowStat>
                    <Tooltip title="Open the gene chart for this user.">
                        <IconButton color="inherit">
                            <DNAIcon style={{ opacity: 0.54 }} />
                        </IconButton>
                    </Tooltip>
                </UserRow>
            ))}
        </UserRowContainer>
    )
}

const ManagementPageContainer = styled.div`
    margin: 20px;
    //align-content: center;
    //justify-content: center;
`

const UserRowStat = styled.div`
    padding: 0 10px;
`

const UserRow = styled.div`
    //border-radius: 5px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr auto auto auto;
    border-bottom: 1px solid lightgrey;
`

const UserRowContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    align-content: start;
    grid-gap: 5px;

    margin: 20px;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 10px;
`

const DeckManagementHome = () => {
    return <LargeMessage message="We haven't implemented the home page yet. Check back later!" icon={CodeTags}></LargeMessage>
}

const DeckManagementSettings = () => {
    return <LargeMessage message="We haven't implemented the settings page yet. Check back later!" icon={CodeTags}></LargeMessage>
}

const TabSelector = () => {
    const route = useRouteMatch([
        '/deck/:deckID/manage/analytics',
        '/deck/:deckID/manage/settings',
        '/deck/:deckID/manage/home',
        '/deck/:deckID/manage/users',
    ])
    const currentTab = route?.path

    const base = window.location.pathname.split('manage')[0] + 'manage'

    return (
        <Tabs value={currentTab}>
            <Tab component={Link} value={`/deck/:deckID/manage/home`} to={`${base}/home`} label="Home"></Tab>
            <Tab component={Link} value={`/deck/:deckID/manage/analytics`} to={`${base}/analytics`} label="Analytics"></Tab>
            <Tab component={Link} value={`/deck/:deckID/manage/users`} to={`${base}/users`} label="Users"></Tab>
            <Tab component={Link} value={`/deck/:deckID/manage/settings`} to={`${base}/settings`} label="Settings"></Tab>
        </Tabs>
    )
}
