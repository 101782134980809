import { gql, useQuery } from '@apollo/client'
import { IconButton } from '@material-ui/core'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { ClientCard } from './client_types'
import { GQLGetCardByIdQuery, GQLGetCardIDsFromTagQuery, GQLGetCardsFromTagQuery } from './generated/client-graphql-types'
import { ReviewableCard } from './reviewer/Card'
import { ReviewerContainer } from './reviewer/ReviewerShared'
import { UnsavedProgressDiv } from './reviewer/TryItReviewer'
import { LiveCardMeta } from './types'
import { DataNotLoaded } from './Utils'

const GET_CARD_IDS_FROM_TAG = gql`
    query getCardIDsFromTag($tagID: ID!) {
        getTagByID(tagID: $tagID) {
            id
            cards {
                id
                index
            }
        }
    }
`

export const GET_CARDS_FROM_TAG = gql`
    query getCardsFromTag($tagID: ID!) {
        getTagByID(tagID: $tagID) {
            id
            cards {
                id
                source
                index

                comments {
                    author {
                        displayName
                        picture
                        id
                    }
                    contents
                    likes
                    timeCreated
                }
            }
        }
    }
`

const GET_CARD_BY_ID = gql`
    query getCardById($cardId: ID!) {
        card(cardId: $cardId) {
            id
            type
            status
            interval
            timesSeen
            due
            source

            comments {
                author {
                    displayName
                    picture
                    id
                }
                contents
                likes
                timeCreated
            }
        }
    }
`

export const TopLevelTagViewer = (props: any) => <TagViewer tagID={props.match.params.tagID} index={props.match.params.index} />

/*
const TagViewer = ({ tagID }: { tagID: string }) => {
    const { data, error, loading } = useQuery<GQLGetCardIDsFromTagQuery>(GET_CARD_IDS_FROM_TAG, {
        variables: { tagID: tagID },
        errorPolicy: 'all',
    })

    if (error || loading || !data) {
        return <DataNotLoaded {...{ loading, error, data }}></DataNotLoaded>
    }

    return (
        <TagViewerContainer>
            {data.getTagByID.cards?.map((c) => (
                <CardRepresentation key={c.id} onClick={(e) => window.open(`view/card/${c.id}`, '_blank')}>
                    {c.index}
                </CardRepresentation>
            ))}
        </TagViewerContainer>
    )
}
*/

export const TagViewerMetaContainer = styled.div`
    background-color: papayawhip;
    width: 100%;
    padding: 10px;
    margin: auto;
    text-align: center;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
`

const TagViewer = ({ tagID, index }: { tagID: string; index?: string }) => {
    const { data, error, loading } = useQuery<GQLGetCardsFromTagQuery>(GET_CARDS_FROM_TAG, {
        variables: { tagID: tagID },
        errorPolicy: 'all',
    })

    const history = useHistory()

    const toIndex = (i: number) => {
        history.push(`/view/tag/${tagID}/index/${i}`)
    }

    if (!index || index === '') {
        toIndex(0)
    }

    const cardIndex = parseInt(index!)

    if (error || loading || !data) {
        return <DataNotLoaded {...{ loading, error, data }}></DataNotLoaded>
    }

    const cards = data?.getTagByID.cards as ClientCard[]
    const selected = cards[cardIndex]

    return (
        <ReviewerContainer>
            <UnsavedCardViewer
                card={selected}
                metaComponent={() => (
                    <TagViewerMetaContainer>
                        <IconButton
                            onClick={(e) => {
                                toIndex(cardIndex! - 1)
                            }}
                        >
                            <KeyboardArrowLeft></KeyboardArrowLeft>
                        </IconButton>
                        <p>
                            You're viewing this tag in standalone mode. The answering buttons are disabled, and no reviews are
                            saved.
                        </p>
                        <IconButton
                            onClick={(e) => {
                                toIndex(cardIndex! + 1)
                            }}
                        >
                            <KeyboardArrowRight></KeyboardArrowRight>
                        </IconButton>
                    </TagViewerMetaContainer>
                )}
            ></UnsavedCardViewer>
        </ReviewerContainer>
    )
}

const CardRepresentation = styled.div`
    background-color: lightblue;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
    height: 40px;
`

const TagViewerContainer = styled.div`
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
    display: grid;
    justify-content: center;
    align-content: center;
`

export const TopLevelCardViewer = (props: any) => <StandaloneCardViewer cardId={props.match.params.cardId} />

const StandaloneCardViewer = ({ cardId }: { cardId: string }) => {
    const { data, error, loading } = useQuery<GQLGetCardByIdQuery>(GET_CARD_BY_ID, { variables: { cardId } })

    if (error || loading || !data || !data.card) {
        return <DataNotLoaded {...{ loading, error, data }}></DataNotLoaded>
    }

    return (
        <ReviewerContainer>
            <UnsavedCardViewer
                card={data.card as any}
                metaComponent={() => (
                    <UnsavedProgressDiv>
                        You're viewing this card in standalone mode. The answering buttons are disabled, and no reviews are saved.
                    </UnsavedProgressDiv>
                )}
            ></UnsavedCardViewer>
        </ReviewerContainer>
    )
}

const UnsavedCardViewer = ({ card, metaComponent }: { card: ClientCard; metaComponent: any }) => {
    const lcmOverrides: (l: LiveCardMeta) => LiveCardMeta = (lcmDefault) => ({
        ...lcmDefault,
        onNext: () => {},
        intervals: {
            remembered: 'Never',
            forgot: 'Never',
        },
        disabled: true,
    })

    return <ReviewableCard card={card as any} overrides={lcmOverrides} metaComponent={metaComponent}></ReviewableCard>
}
