import { gql, useMutation } from '@apollo/client'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { LockOpen } from 'mdi-material-ui'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { ClientUser } from '../client_types'
import { GQLAddByTagMutation } from '../generated/client-graphql-types'
import { doLogin } from '../Login'
import { useAddMoreCards, DEFAULT_ADDITIONAL_NEW_CARDS } from '../reviewer/Card'
import { Deck, User } from '../shared/types'
import { useSecrets } from './DeckPreview'

const SignInToLearn = ({ deck }: { deck: Deck }) => {
    const history = useHistory()
    return <BlueButton onClick={() => history.push(`/try/${deck.id}`)}>Learn</BlueButton>
}
const PreviewButton = ({ deck }: { deck: Deck }) => {
    const history = useHistory()
    return <BlueButton onClick={() => history.push(`/try/${deck.id}`)}>Preview</BlueButton>
}

/*
    const [learnOpen, setLearnOpen] = useState(false)
            <Dialog open={learnOpen} onClose={() => setLearnOpen(false)}>
                <DialogTitle>Sign in to save your progress</DialogTitle>
                <DialogContent>
                    To save your progress, you need to sign in. If you don't want to sign in, you can still try out this deck.
                    You'll see randomly selected cards and your progress won't be saved. Sign in when you're ready to get started
                    for real!
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setLearnOpen(false)}>Cancel</Button>
                    <Button
                        onClick={() => {
                            history.push(`/try/${deck.id}`)
                        }}
                        variant="outlined"
                    >
                        Try without saving progress
                    </Button>

                    <Button onClick={doLogin()} variant="outlined">
                        Sign in with Google
                    </Button>
                </DialogActions>
            </Dialog>
        </>
        */

const BlueButton = styled(Button)`
    background-color: #6772e5;
    color: #fff;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    padding: 7px 14px;
    margin: 0px 10px 0px 0px;
    &:hover {
        background-color: #5469d4;
    }
`

const LoadingButton = () => (
    <Button variant="outlined" disabled>
        <CircularProgress variant="indeterminate" color="secondary" size="1.5em" />
    </Button>
)

const DoneButton = () => (
    <Button variant="outlined" disabled>
        Done
    </Button>
)

const AddMoreButton = ({ deck }: { deck: Deck }) => {
    const moreCards = useAddMoreCards(deck.id, DEFAULT_ADDITIONAL_NEW_CARDS)

    return (
        <MoreCardsContainer>
            <Button variant="outlined" disabled>
                Done for today
            </Button>
            <MoreCardsLink onClick={() => moreCards()}>Add 10 more cards today</MoreCardsLink>
        </MoreCardsContainer>
    )
    /*
    return (
        <Button variant="contained" color="primary" onClick={() => moreCards()}>
            Add {DEFAULT_ADDITIONAL_NEW_CARDS} More Cards
        </Button>
    )
    */
}

const MoreCardsLink = styled.a`
    color: deepskyblue;
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.8em;
    margin: auto;
`

const MoreCardsContainer = styled.div`
    display: grid;
`

const StudyButton = ({ deck, cardsLeft }: { deck: Deck; cardsLeft: number }) => {
    const history = useHistory()
    return (
        <Button
            variant="contained"
            color="primary"
            onClick={() => {
                history.push(`/review/${deck.id}`)
            }}
        >
            Study ({cardsLeft})
        </Button>
    )
}

const ComeBackTomorrowButton = () => {
    return (
        <Button disabled variant="outlined">
            Done for today
        </Button>
    )
}

const ADD_TAG = gql`
    mutation addByTag($id: ID!) {
        addByTag(tagID: $id) {
            success
        }
    }
`

const GetButton = ({
    deck,
    setLoading,
    refetch,
}: {
    deck: Deck
    setLoading: (loading: boolean) => void
    refetch: () => Promise<void>
}) => {
    const [addByTag, _] = useMutation<GQLAddByTagMutation>(ADD_TAG)

    return (
        <BlueButton
            onClick={async () => {
                setLoading(true)
                await addByTag({ variables: { id: deck.id } })
                await refetch()
                setLoading(false)
            }}
        >
            Add deck
        </BlueButton>
    )
}

const LockedButton = ({ deck }: { deck: Deck }) => {
    const [open, setOpen] = useState(false)
    return (
        <>
            <BlueButton color="primary" variant="contained" disabled={!deck.externalURL} onClick={() => setOpen(true)}>
                <LockOpen></LockOpen>
                Unlock
            </BlueButton>
            <Dialog onClose={() => setOpen(false)} open={open}>
                <DialogTitle>Deck locked</DialogTitle>
                <DialogContent>
                    Please purchase this deck on the Refold website. You will have permanent access once purchased.
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>OK</Button>
                    <Button
                        onClick={() => {
                            window.open(deck.externalURL!, '_blank')
                            setOpen(false)
                        }}
                    >
                        Go to Refold
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export const ActionButton = ({
    user,
    deck,
    loading,
    locked,
    left,
    refetch,
}: {
    user: ClientUser | null
    deck: Deck
    loading: boolean
    locked: boolean
    left: number
    refetch: () => Promise<void>
}): JSX.Element => {
    const [isLoading, setLoading] = useState(false)
    const [secret, setSecret] = useSecrets(deck.id)

    if (!user) {
        if (!locked || secret) {
            return <SignInToLearn deck={deck} />
        }
        return (
            <>
                <PreviewButton deck={deck}></PreviewButton>
                <LockedButton deck={deck}></LockedButton>
            </>
        )
    } else if (isLoading || loading) {
        return <LoadingButton />
    } else if ((deck as any).tag.progress === 1 && left === 0) {
        return <ComeBackTomorrowButton />
    } else if (deck.learning && left !== 0) {
        return <StudyButton deck={deck} cardsLeft={left}></StudyButton>
    } else if (deck.learning) {
        return <AddMoreButton deck={deck}></AddMoreButton>
    } else if (locked) {
        return (
            <>
                <PreviewButton deck={deck}></PreviewButton>
                <LockedButton deck={deck}></LockedButton>
            </>
        )
    } else {
        return <GetButton {...{ deck, setLoading, refetch }}></GetButton>
    }
}

const MultiActionContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
`
