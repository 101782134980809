import { gql, useMutation } from '@apollo/client'
import { Button } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import _ from 'lodash'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { DrawerContext } from '../App'
import { ClientCard } from '../client_types'
import { ALL_CARD_FIELDS_FRAGMENT } from '../fragments'
import { GQLCardStatus, GQLDoReviewMutation, GQLExtraNewsMutation } from '../generated/client-graphql-types'
import { backendURL, CoreCardSchedule, updateAfterReview } from '../shared/sharedutil'
import { newDate, newDuration } from '../shared/times'
import { AnswerType, ClientReviewInfo } from '../shared/types'
import { Intervals, LiveCardMeta } from '../types'
import { CommentsSection } from './Comments'
import { GET_REVIEWS, GET_REVIEWS_NEWS_ONLY } from './DeckReviewer'

export const CardInternal = styled.div<{ bgColor: string | undefined }>`
    display: grid;
    align-items: center;
    justify-items: center;
    justify-content: center;
    align-content: center;
    padding: 1vw;
    background-color: ${(props) => props.bgColor || 'transparent'};
`

const IFrameCardInternal = styled.div<{ drawer: boolean }>`
    display: grid;
    width: 1000px;
    max-width: ${(props) => (props.drawer ? 'calc(100vw - 240px)' : 'calc(100vw)')};
`

const EXTRA_NEWS = gql`
    mutation extraNews($tagID: ID!, $howMany: Int!) {
        extraNews(tagID: $tagID, howMany: $howMany) {
            id
        }
    }
`

const handleGradingKeypresses = (
    isCardFlipped: boolean,
    onEvent: (a0: any) => void,
    keydownEvent: { code: string; keyCode: number }
) => {
    if (['Space', 'Enter'].includes(keydownEvent.code)) {
        if (isCardFlipped) {
            onEvent({ type: 'grading', value: 'Remembered' })
        } else {
            onEvent({ type: 'flip' })
        }
    } else if (['Key1', 'KeyF'].includes(keydownEvent.code)) {
        if (isCardFlipped) {
            onEvent({
                type: 'grading',
                value: 'Forgot',
            })
        }
    } else if (['Key3', 'KeyC'].includes(keydownEvent.code)) {
        if (isCardFlipped) {
            onEvent({
                type: 'grading',
                value: 'Remembered',
            })
        }
    }
}

//console.log(data)
//document.body.dispatchEvent(
//    new KeyboardEvent('keydown', {
//        key: data.key,
//        code: data.code,
//        bubbles: true,
//        //@ts-ignore
//        which: data.keyCode,
//        //@ts-ignore
//        keyCode: data.keyCode,
//    })
//)

// this is still a bit buggy but whatever. If you go into the iframe and press a non-s key then s, it wont work.
const simulateKeypress = (keyCode: number) => {
    const eventObj = document.createEvent('Events')
    eventObj.initEvent('keydown', true, true)
    //@ts-ignore
    eventObj.keyCode = keyCode
    //@ts-ignore
    eventObj.which = keyCode
    document.body.dispatchEvent(eventObj)
}

const rewriteMedia = (html: string) =>
    html.replace(/src="media/g, `src="${backendURL}/media`).replace(/href="media/g, `href="${backendURL}/media`)
//.replace(/<audio src="media/g, `<audio controls autoplay src="${backendURL}/media`)
//.replace(/<img src="media/g, `<img src="${backendURL}/media`)

/*
when a keypress happens in child, it sends a message with type keypress-child.
when a keypress happens in parent, it sends a message with type keypress-parent.

parent and child should both agree about keypresses that happen.
*/

// all keypresses need to be sent to

type KeypressMessage = { type: 'keypress-parent' | 'keypress-child'; keyCode: number; key: string; code: string }

const useMirrorKeypressesToChild = (postMessages: (arg0: any) => void) => {
    useEffect(() => {
        const sendKeypressToChild = (event: any) => {
            if (event.repeat) return
            const elt = event.target || event.srcElement
            if (elt?.isContentEditable || ['INPUT', 'TEXTAREA', 'SELECT'].includes(elt?.tagName)) return

            // prevent spaces from scrolling down the page.
            if (event.keyCode === 32) {
                event.preventDefault()
            }

            postMessages({
                type: 'keypress-parent',
                keyCode: event.keyCode,
                key: event.key,
                code: event.code,
            } as KeypressMessage)
        }

        window.addEventListener('keydown', sendKeypressToChild)
        return () => window.removeEventListener('keydown', sendKeypressToChild)
    }, [postMessages])
}

export const Card = ({ setCardFlipped, isCardFlipped, onNext, source, id, disabled }: ClientCard & LiveCardMeta) => {
    const frameEl = useRef<HTMLIFrameElement>(null)

    const postMessage = (data: any) => {
        console.log('posting a message to both parent and child:', data)
        window.postMessage(data, '*')
        frameEl?.current?.contentWindow?.postMessage(data, '*')
    }

    useEffect(() => {
        const r = Math.random() + ''

        type EventType = { type: string } & any
        const messageHandlerParent = ({ data }: { data: EventType }) => {
            if (data.type === 'grading') {
                onNext(data.value as AnswerType, r)
            } else if (data.type === 'flip') {
                setCardFlipped(true)
            } else if (data.type === 'keypress-child' || data.type === 'keypress-parent') {
                //handleGradingKeypresses(isCardFlipped, postMessage, { code: data.code, keyCode: data.keyCode })
                if (window.custom_hotkeys) {
                    window.custom_hotkeys.filter((c) => c.code === data.code).forEach(({ fn }) => fn())
                }
            }
        }

        window.addEventListener('message', messageHandlerParent)

        if (disabled) {
            console.log('disabled is true', disabled)
            window.setTimeout(() => postMessage({ type: 'disableAnswers' }), 200)
        }

        return () => window.removeEventListener('message', messageHandlerParent)
    }, [onNext, setCardFlipped, isCardFlipped, postMessage])

    useMirrorKeypressesToChild(postMessage)

    return <iframe ref={frameEl} className="card-iframe" title={id + ''} srcDoc={rewriteMedia(source)}></iframe>
}

export const useAddMoreCards = (deckID: string, howMany: number) => {
    const [mutate] = useMutation<GQLExtraNewsMutation>(EXTRA_NEWS, {
        variables: { tagID: deckID, howMany },
        refetchQueries: [
            { query: GET_REVIEWS_NEWS_ONLY, variables: { tagID: deckID, startIdx: -(howMany + 5), endIdx: undefined } },
        ],
        awaitRefetchQueries: true,
    })

    return mutate
}

export const DEFAULT_ADDITIONAL_NEW_CARDS = 10

export const EndOfDeckCard = () => {
    const { deckID } = useParams<{ deckID: string }>()
    const history = useHistory()

    const mutate = useAddMoreCards(deckID, DEFAULT_ADDITIONAL_NEW_CARDS)

    return (
        <EndOfDeckCardInternal>
            Congratulations! You're all done for today.
            <Button
                color="primary"
                variant="contained"
                onClick={() => {
                    history.push('/')
                }}
            >
                Back to Decks
            </Button>
            <Button
                color="primary"
                variant="contained"
                onClick={() => {
                    mutate()
                }}
            >
                Add {DEFAULT_ADDITIONAL_NEW_CARDS} More New Cards
            </Button>
        </EndOfDeckCardInternal>
    )
}
/*
            <Button
                color="primary"
                variant="contained"
                onClick={() => {
                    history.push(`/preview/${deckID}`)
                }}
            >
                Check out stats!
            </Button>
*/

const EndOfDeckCardInternal = styled.div`
    display: grid;
    grid-gap: 20px;
    justify-items: center;
    align-items: center;
    margin: auto auto;
`

const toCore = (c: ClientCard): CoreCardSchedule => {
    return {
        ...c,
        firstSeen: c.firstSeen ? newDate(c.firstSeen).toDate() : c.firstSeen,
        due: c.due ? newDate(c.due).toDate() : c.due,
    }
}

export const getIntervals = (card: CoreCardSchedule): Intervals => {
    if (!card.status) {
        return {
            remembered: 'No Interval',
            forgot: 'No Interval',
        }
    }

    const now = newDate()

    const forgotTimestamp = updateAfterReview(card, now.toDate(), AnswerType.Forgot).due
    const rememberedTimestamp = updateAfterReview(card, now.toDate(), AnswerType.Remembered).due

    if (!forgotTimestamp || !rememberedTimestamp) {
        return {
            remembered: 'Never',
            forgot: 'Never',
        }
    }

    const forgotTime = newDuration(now.diff(forgotTimestamp))
    const rememberedTime = newDuration(now.diff(rememberedTimestamp))

    return {
        remembered: rememberedTime.humanize(),
        forgot: forgotTime.humanize(),
    }
}

const DO_REVIEW = gql`
    mutation doReview(
        $cardId: ID!
        $answer: AnswerType!
        $reviewedAt: Date!
        $frontTimeMS: Int!
        $totalTimeMS: Int!
        $other: String
    ) {
        review(
            cardId: $cardId
            answer: $answer
            reviewedAt: $reviewedAt
            frontTimeMS: $frontTimeMS
            totalTimeMS: $totalTimeMS
            other: $other
        ) {
            id
            status
            due
            interval
            timesSeen
        }
    }
`

type toGQLStatus<T> = Omit<T, 'status'> & { status: GQLCardStatus }

export const useSubmitReview = () => {
    const [rawSubmitReview] = useMutation<GQLDoReviewMutation>(DO_REVIEW)

    const submitReview = (
        id: string,
        ans: AnswerType,
        frontTimeMS: number,
        totalTimeMS: number,
        card: CoreCardSchedule,
        sendOnReview: any
    ): ClientReviewInfo & { cardId: string } => {
        // doing TS here is super annoying.
        // It's difficult because It MATTERS whether an object pair is (x, undefined) or just doesn't exist.
        // if you accidentally make the object {x: undefined} instead of {}, undefined will overwrite the previous value of x.
        const updateRaw = updateAfterReview(card, newDate().toDate(), ans)
        const update = _.omitBy(
            {
                ...updateRaw,
                due: updateRaw.due ? updateRaw.due?.toISOString() : undefined,
                status: updateRaw.status as GQLCardStatus | undefined,
            },
            (x) => x === undefined
        )

        const updatedCard = { ...card, ...update } as any

        const clientReview = {
            cardId: id,
            answer: ans,
            reviewedAt: newDate().toDate(),
            totalTimeMS,
            frontTimeMS,
            other: JSON.stringify(sendOnReview),
        }

        rawSubmitReview({
            variables: {
                ...clientReview,
            },
            optimisticResponse: {
                __typename: 'Mutation',
                review: {
                    __typename: 'Card',
                    ...updatedCard,
                },
            },
        })

        return clientReview
    }

    return submitReview
}

//setTimeout(() => amplitude.getInstance().logEvent('card:flip', card), 3000)

export const ReviewableCard = ({
    card,
    metaComponent,
    overrides,
    onReview,
    sendOnReview,
}: {
    card: ClientCard
    metaComponent?: (l: LiveCardMeta) => React.ReactNode
    overrides?: (l: LiveCardMeta) => LiveCardMeta
    onReview?: (a: ReturnType<ReturnType<typeof useSubmitReview>>) => void
    sendOnReview?: Record<string, any>
}) => {
    const [isCardFlipped, setCardFlipped] = useState(false)
    const [startTime, setStartTime] = useState(newDate())
    const [flipTime, setFlipTime] = useState(newDate())

    const submitReview = useSubmitReview()

    const drawerOpen = useContext(DrawerContext)

    const onNext = (wasCorrect: AnswerType, other: string) => {
        const r = Math.random() + ''

        const finalReview = submitReview(
            card.id,
            wasCorrect,
            flipTime.diff(startTime, 'millisecond'),
            newDate().diff(startTime, 'millisecond'),
            card,
            { ...sendOnReview, fromHandler: other, frontOnNext: r }
        )
        if (onReview) onReview(finalReview)
        window.localStorage['activeCardId'] = null
        setCardFlipped(false)
        setStartTime(newDate())
        setFlipTime(newDate())
    }

    let liveCardMeta: LiveCardMeta = {
        isCardFlipped,
        setCardFlipped: (isFlipped: boolean) => {
            setCardFlipped(isFlipped)
            setFlipTime(newDate())
        },
        onNext,
        intervals: getIntervals(toCore(card)),
    }

    liveCardMeta = overrides ? overrides(liveCardMeta) : liveCardMeta

    return (
        <ReviewableCardContainer>
            <IFrameCardInternal drawer={!!drawerOpen}>
                <Card {...card} {...liveCardMeta} />
            </IFrameCardInternal>
            <AnswerClusterPaper>{metaComponent && metaComponent(liveCardMeta)}</AnswerClusterPaper>

            {isCardFlipped && <CommentsSection card={card} cardId={card.id} comments={card.comments} />}
        </ReviewableCardContainer>
    )
}

const AnswerClusterPaper = styled(Paper)`
    display: grid;
`

//key={liveCard.id + liveCard.due}

/*
const CardBreadcrumbs = ({ tags }: { tags: CombinedCard[] }) => {
    return (
        <Breadcrumbs separator="›">
            {tags.map((t: CombinedCard, i: number) => (
                <Link
                    key={t.data.title}
                    rel="noopener noreferrer"
                    target="_blank"
                    href={t.data.links[0]}
                    color={i === tags.length - 1 ? 'textPrimary' : 'inherit'}
                    onClick={() => null}
                >
                    {t.data.title}
                </Link>
            ))}
        </Breadcrumbs>
    )
}
*/

const ReviewableCardContainer = styled.div`
    display: grid;
    grid-gap: 5px;
    @media (max-width: 500px) {
        grid-gap: 0px;
    }
    grid-template-rows: 1fr auto;
`
