//@ts-ignore
import Hashes from 'jshashes'
import _ from 'lodash'
import random from 'random'
import { Interleaver, makeInterleaver } from './interleaver'
import { startOfAdjacentSRSDay, toGlobalSRSDay } from './srsDays'
import { DateType, newDate, newDuration } from './times'
import { AnswerType, Card, CardID, CardSchedule, CardStatus, CardType, ContentDifficulty, Seconds } from './types'

//export const learningSteps = [60, 10 * 60]
export const learningSteps = [newDuration(1, 'minute'), newDuration(10, 'minutes')]
//export const initialInterval = 24 * 60 * 60
export const initialInterval = newDuration(1, 'day')
export const instaCorrectInitialInterval = newDuration(3.25, 'day')
export const intervalMultiplier = 3.5

export const LATEST_VERSION = 6
export const REVIEW_TOO_LONG_TIME_MS = 60 * 5 * 1000
export const REVIEW_TOO_LONG_TIME = newDuration(5, 'minutes')

//new -> 600 -> 60 -> 600 -> 86400

export type CoreCardSchedule = Pick<CardSchedule, 'status' | 'interval' | 'timesSeen' | 'firstSeen'> & {
    id: CardID
    due: Date | null
    index: number
}

type UpdateAfterReviewCaseFn = (
    ...[arg0, arg1, arg2, arg3]: Parameters<typeof updateAfterReview>
) => Pick<CoreCardSchedule, 'due' | 'interval' | 'status'>

const forgotMemorized: UpdateAfterReviewCaseFn = (before, reviewedAt, answer, options) => {
    return {
        due: newDate(reviewedAt).add(learningSteps[1]).toDate(),
        interval: learningSteps[1].asSeconds(),
        status: CardStatus.Learning,
    }
}

const forgotNewOrLearning: UpdateAfterReviewCaseFn = (before, reviewedAt, answer, options) => {
    return {
        due: newDate(reviewedAt).add(learningSteps[0]).toDate(),
        interval: learningSteps[0].asSeconds(),
        status: CardStatus.Learning,
    }
}

const rememberedNew: UpdateAfterReviewCaseFn = (before, reviewedAt, answer, options) => {
    if (before.interval !== 0) {
        // card is accelerated
        return {
            due: newDate(reviewedAt).add(before.interval, 'seconds' /*instaCorrectInitialInterval*/).toDate(),
            status: CardStatus.Memorized,
            interval: before.interval, //instaCorrectInitialInterval.asSeconds(),
        }
    } else if (before.index < 100) {
        // card is not accelerated
        return {
            due: newDate(reviewedAt).add(learningSteps[1]).toDate(),
            status: CardStatus.Learning,
            interval: learningSteps[1].asSeconds(),
        }
    } else {
        return {
            due: newDate(reviewedAt).add(instaCorrectInitialInterval).toDate(),
            status: CardStatus.Memorized,
            interval: instaCorrectInitialInterval.asSeconds(),
        }
    }
    /*
    const difficulty = (options?.difficulty || ContentDifficulty.Normal)
    if (difficulty === ContentDifficulty.Easy) {
        // easy. user knows content well, push cards far into the future.
    } else if (difficulty === ContentDifficulty.Normal) {
        //normal, see new cards tomorrow.
        return {
            due: newDate(reviewedAt).add(initialInterval).toDate(),
            status: CardStatus.Memorized,
            interval: initialInterval.asSeconds(),
        }
    } else {
        // hard, keep new cards around for a learning step.
    } 
    */
}

const rememberedLearning: UpdateAfterReviewCaseFn = (before, reviewedAt, answer, options) => {
    const newLearningStep = learningSteps.map((d) => d.asSeconds()).indexOf(before.interval) + 1

    if (newLearningStep === learningSteps.length) {
        return {
            due: newDate(reviewedAt).add(initialInterval).toDate(),
            status: CardStatus.Memorized,
            interval: initialInterval.asSeconds(),
        }
    } else {
        return {
            due: newDate(reviewedAt).add(learningSteps[newLearningStep]).toDate(),
            interval: learningSteps[newLearningStep].asSeconds(),
            status: CardStatus.Learning,
        }
    }
}

export const suspendInterval = newDuration(10, 'years')

const suspendAny: UpdateAfterReviewCaseFn = (before, reviewedAt, answer, options) => {
    return {
        due: newDate(reviewedAt).add(suspendInterval).toDate(),
        interval: suspendInterval.asSeconds(),
        status: CardStatus.Memorized,
    }
}

const rememberedMemorized: UpdateAfterReviewCaseFn = (before, reviewedAt, answer, options) => {
    const lastReviewTime = newDate(before.due!).subtract(before.interval, 'seconds')
    const lastInterval = newDuration(newDate(reviewedAt).diff(lastReviewTime)).asSeconds()
    //const lastInterval = reviewTimestamp - before.due! + before.interval // this ended up being 9? So if a card was seen at 50 and scheduled for 80, we review at 100, lastInterval should be 50.
    //const lastIntervalDays = roundSecondsToDay(lastInterval + (86400 / 2 - 1)) // round up to days. so 13500s + 2 days => 3 days.

    // however, if a card was seen at 50 and scheduled for 80, but we happened to see it at 55, then this should just keep the old interval.
    const rawIntervalS = lastInterval * intervalMultiplier
    const fuzzedIntervalS = options?.fuzz ? Math.round(fuzzInterval(rawIntervalS)) : rawIntervalS // Math.round to make it an integer number of seconds
    const newIntervalS = roundSecondsToDay(fuzzedIntervalS)

    if (newIntervalS < before.interval) {
        // for some reason we reviewed early?
        console.log('Reviewed early.')
    }
    let largerInterval = newDuration(Math.max(newIntervalS, before.interval), 'seconds')
    return {
        due: newDate(reviewedAt).add(largerInterval).toDate(),
        interval: largerInterval.asSeconds(),
        status: CardStatus.Memorized,
    }
}

export const updateAfterReview = (
    before: CoreCardSchedule,
    reviewedAt: Date,
    answer: AnswerType,
    options?: { fuzz?: boolean; difficulty?: ContentDifficulty }
): Partial<CoreCardSchedule> => {
    let update

    if (answer === AnswerType.Forgot && before.status === CardStatus.New) {
        update = forgotNewOrLearning(before, reviewedAt, answer, options)
    } else if (answer === AnswerType.Forgot && before.status === CardStatus.Learning) {
        update = forgotNewOrLearning(before, reviewedAt, answer, options)
    } else if (answer === AnswerType.Forgot && before.status === CardStatus.Memorized) {
        update = forgotMemorized(before, reviewedAt, answer, options)
    } else if (answer === AnswerType.Remembered && before.status === CardStatus.New) {
        update = rememberedNew(before, reviewedAt, answer, options)
    } else if (answer === AnswerType.Remembered && before.status === CardStatus.Learning) {
        update = rememberedLearning(before, reviewedAt, answer, options)
    } else if (answer === AnswerType.Remembered && before.status === CardStatus.Memorized) {
        update = rememberedMemorized(before, reviewedAt, answer, options)
    } else if (answer === AnswerType.Suspended) {
        update = suspendAny(before, reviewedAt, answer, options)
    } else {
        throw new Error('No appropriate post-review update case found. ' + answer + JSON.stringify(before))
    }

    //console.info(`Review ${answer}. Status: ${before.status} => ${update.status || before.status}.
    //Reviewed @ ${reviewedAt}; was due @ ${'due' in before ? before.due : 'never [new card]'}; prior interval: ${
    //    before.interval
    //}; new interval: ${update.interval}; new due time: ${update.due}`)

    const out = {
        ...update,
        timesSeen: (before.timesSeen || 0) + 1,
        firstSeen: before.status === CardStatus.New ? reviewedAt : undefined,
    }

    return _.omitBy(out, (x) => x === undefined)
}

// if you want to fuzz a 90 day interval so that 95% of reviews fall within 70-110 days:
// 95% of a distribution is in a 4-sigma chunk centered on the origin. So 70-110 days has s=10 days.
// If you call fuzzInterval with base=90, s=10, you'll get 95% of the outputs within [70, 110].
// If you call fuzzInterval with base=3, s=1/3, rounding will cause anything outside of [2.5, 3.5] to be 2 or 4.
// Since 3-2.5 is .5, and .5/(s=.33) is 1.5, approximately 89% of reviews will fall on day 3 after rounding.
// In general, for good results, use stddev = base / 9.
const fuzzInterval = (base: number): number => {
    return random.normal(base, base / 9)()
}

export const roundSecondsToDay = (s: number) => (s < 86400 ? s : 86400 * Math.round(s / 86400))

type ReviewableCard = Pick<Card & CardSchedule, 'due' | 'status' | 'id' | 'interval' | 'type'>
export const toReviewByStatus = <T extends ReviewableCard>(now: DateType, cards: T[], rolloverSecond: number) => {
    cards = cards.filter((c) => !c.due || newDate(c.due).isBefore(startOfNextSRSDay(now, rolloverSecond)))

    const byStatus = _(cards).groupBy('status').value()

    const news = _(byStatus[CardStatus.New]).sortBy('index').value()
    const [learningReady, learningNotReady] = _(byStatus[CardStatus.Learning])
        .sortBy('due')
        .partition((c) => newDate(c.due!).isBefore(now))
        .value()

    const [learningReadyFresh, learningReadyStale] = _(learningReady)
        .partition((c) => toGlobalSRSDay(newDate(c.due!), rolloverSecond) >= toGlobalSRSDay(newDate(), rolloverSecond))
        .value()

    const memorized = _(byStatus[CardStatus.Memorized])
        .sortBy((c) => new Hashes.MD5().hex(`${c.id} + ${c.due}`))
        .value()

    //const news = _(cards).filter(c => c.status === CardStatus.New).sortBy('index').value()
    //const learningReady = cards.filter(c => c.status === 'learning' && c.due <= secondsSinceEpoch()).sort((a, b) => a.due - b.due)
    //const learningNotReady = cards.filter(c => c.status === 'learning' && c.due > secondsSinceEpoch()).sort((a, b) => a.due - b.due)
    //const memorized = _.chain(cards).filter(c => c.status === 'memorized').sortBy("_id").value()

    return {
        learningReadyFresh,
        learningReadyStale,
        news,
        memorized,
        learningNotReady,
    }
}

export const leftToReview = <T extends ReviewableCard>(cards: T[], interleave: Interleaver<T>, rolloverSecond: number) => {
    //, options?: {newsFirst: boolean}) => {
    const { learningReadyFresh, learningReadyStale, news, memorized, learningNotReady } = toReviewByStatus(
        newDate(),
        cards,
        rolloverSecond
    )

    const remaining = _.concat(learningReadyFresh, interleave([memorized, news, learningReadyStale]), learningNotReady)

    const learnReviewsLeft = _(learningReadyFresh)
        .concat(learningNotReady)
        .concat(learningReadyStale)
        .map((x) => learningSteps.length - learningSteps.map((d) => d.asSeconds()).indexOf(x.interval))
        .sum()

    const introLeft = news.filter((c) => c.type === CardType.Intro || c.type === CardType.Info).length

    return {
        remaining,
        newLeft: news.filter((c) => c.type !== CardType.Intro).length,
        learningLeft: learnReviewsLeft,
        memorizedLeft: memorized.length,
        introLeft,
    }
}

export const numLeft = <T extends ReviewableCard>(cards: T[], rolloverSecond: number): number => {
    const toReview = leftToReview(cards, makeInterleaver(3), rolloverSecond)

    return toReview.newLeft + toReview.learningLeft + toReview.memorizedLeft + toReview.introLeft
}

// EST is UTC-4. So at 6p here it's 10p UTC, and 4a here is 8a UTC. So we want to do rolloverTime - offset.
// PST is UTC-7. So 4a PT is 11a UTC.
export const startOfNextSRSDay = (date: DateType, rolloverSecond: number) => {
    return startOfAdjacentSRSDay(date, rolloverSecond, 1)
}

export const averageTimesToNewCount = (averageTimes: Record<CardStatus, Seconds>, targetTime: Seconds) => {
    const ratio = 5 // 5 memorized for every 1 due.
    const groupTime = averageTimes[CardStatus.New] + averageTimes[CardStatus.Memorized] * ratio

    const cappedGroupTime = Math.max(groupTime, 15)

    const totalGroups = targetTime / cappedGroupTime

    // 1.35 is because the current thing seems to consistently undershoot by about 50%, so we're forcing it up a bit.
    return { news: Math.floor(totalGroups * 1), memorized: Math.floor(totalGroups * ratio) }
}

export const timeHorizon = 86400

//export const secondsSinceEpoch = (date: Date) => Math.floor(date.getTime() / 1000)

// const prod_url = 'http://localhost:80'
const prod_url = 'https://quantized.co'

export const isProd = process.env.NODE_ENV === 'production'
//export const isProd = false

export const backendURL = isProd ? prod_url : 'http://localhost:8080'
export const frontendURL = isProd ? prod_url : 'http://localhost:3000'

export const hashStringToNumber = (str: string): number => {
    let out = 1
    for (const s of str) {
        out = (out * ('' + s).charCodeAt(0)) % 7001
    }
    return out
}

export enum PreviouslyStudied {
    RTK = 'RKT',
    Core2k = 'Core2k',
    Core6k = 'Core6k',
    Genki1 = 'Genki 1',
    Genki2 = 'Genki 2',
    TangoN5 = 'Tango N5',
    TangoN4 = 'Tango N4',
    Semesters2 = 'Semesters 2',
    Semesters4 = 'Semesters 4',
    WaniKani = 'WaniKani',
    DuoLingo = 'DuoLingo',
    JLPTN5 = 'JLPT N5',
    JLPTN4 = 'JLPT N4',
    JLPTN3 = 'JLPT N3',
}
