import { gql, getApolloContext, useMutation } from '@apollo/client'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Paper } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { GQLResetDeckMutation } from '../generated/client-graphql-types'
import { frontendURL } from '../shared/sharedutil'
import { TagID } from '../shared/types'

export const StandardSetting = ({
    header,
    explanation,
    children,
    disabled,
}: {
    header: string
    explanation: string
    children: JSX.Element
    disabled?: boolean
}) => {
    return (
        <OptionContainer disabled={disabled}>
            <OptionText>
                <OptionHeader>{header}</OptionHeader>
                <OptionExplanation>{explanation}</OptionExplanation>
            </OptionText>
            {children}
        </OptionContainer>
    )
}

export const DeckSettingsReset = () => {
    const { deckID } = useParams<{ deckID: TagID }>()
    return (
        <StandardSetting
            header="Reset Deck"
            explanation="This will delete all your progress and remove the deck from your account."
        >
            <ResetDeckButton deckID={deckID}></ResetDeckButton>
        </StandardSetting>
    )
}

const OptionText = styled.div`
    display: grid;
`

const OptionHeader = styled.div`
    font-weight: 700;
`

const OptionExplanation = styled.div``

const OptionContainer = styled(Paper)<{ disabled?: boolean }>`
    width: 95%;
    max-width: 95vw;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-auto-flow: column;
    margin: auto;
    padding: 15px;
    color: ${(props) => (props.disabled ? 'lightgrey' : 'unset')};
`

const DangerButton = styled(Button)`
    color: crimson;
`

const RESET_DECK = gql`
    mutation resetDeck($tagID: ID!) {
        resetDeck(tagID: $tagID) {
            success
        }
    }
`

const ResetDeckButton = ({ deckID }: { deckID: string }) => {
    const { client: apolloClient } = useContext(getApolloContext())
    const [resetDeck] = useMutation<GQLResetDeckMutation>(RESET_DECK)
    const [open, setOpen] = useState<boolean>(false)
    return (
        <>
            <DangerButton
                variant="outlined"
                onClick={() => {
                    setOpen(true)
                }}
            >
                Delete Deck
            </DangerButton>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Are you absolutely sure?</DialogTitle>
                <DialogContent>
                    This action cannot be undone. This will permanently delete all of your progress on the current deck, and
                    remove it from the "Your Decks" panel.
                </DialogContent>
                <DialogActions>
                    <DangerButton
                        variant="outlined"
                        onClick={async () => {
                            await resetDeck({
                                variables: {
                                    tagID: deckID,
                                },
                            })
                            //await apolloClient!.resetStore()
                            setOpen(false)
                            window.location.replace(`${frontendURL}`)
                        }}
                    >
                        I'm sure
                    </DangerButton>
                    <Button
                        onClick={() => {
                            setOpen(false)
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
