import _ from 'lodash'
import { DateType, newDate } from './times'
import { Seconds, SRSDay, User } from './types'

export const SRS_DAY_GLOBAL_START = newDate('2021-01-01:00:00Z')

export const toUserSRSDay = (date: DateType, user: User): SRSDay => {
    return srsDaysBetween(newDate(user.createdTime), date, user.settings.rolloverSecond)
}

export const toGlobalSRSDay = (date: DateType, rolloverSecond: Seconds): SRSDay => {
    return srsDaysBetween(SRS_DAY_GLOBAL_START, date, rolloverSecond)
}

// Say you're trying to compute SRS days between 2p and 6p with a rollover at 4p.
// subtract off 4p, to get to 10p and 2a, then count how many calendar days between 10p and 2a with startOf()
export const srsDaysBetween = (from: DateType, to: DateType, rolloverSeconds: Seconds): SRSDay => {
    const first = from.subtract(rolloverSeconds, 'seconds').startOf('day')
    const last = to.subtract(rolloverSeconds, 'seconds').startOf('day')

    return last.diff(first, 'day')
}

// go to the start of the `offset`th SRS day from today
// For N = 0, rollover = 1pm
// If it's 2pm return 1pm
// If it's 1pm, return 1pm
// If it's noon, return 1pm the previous day
export function startOfAdjacentSRSDay(date: DateType, rolloverSecond: Seconds, offset: number): DateType {
    const startOfCurSRSDay = date.subtract(rolloverSecond, 'seconds').startOf('day')
    const NthSRSDayAfter = startOfCurSRSDay.add(offset, 'day')
    return NthSRSDayAfter.add(rolloverSecond, 'seconds')
}
