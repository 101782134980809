import { gql, useQuery } from '@apollo/client'
import { Button, FormControlLabel, Switch } from '@material-ui/core'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { toGlobalSRSDay, toUserSRSDay } from '../shared/srsDays'
import { newDate } from '../shared/times'
import { AnswerType, CardStatus, FullReviewInfo, FullUserStats } from '../shared/types'
import { DataNotLoaded } from '../Utils'

export const GET_ALL_USERS_FULL_STATS = gql`
    query getAllUserStats {
        getAllUserStats
    }
`

export const labelOutliers = <T,>(values: T[], getNum: (arg0: T) => number): (T & { exists?: boolean })[] => {
    if (values.length <= 10) {
        return values
    }
    return values.map((x) => (getNum(x) / 1000 > 300 ? { ...x, outlier: true } : x))
}

const UserRowsContainer = styled.div`
    display: grid;
    grid-auto-flow: row;
    grid-gap: 5px;
`

const UserRowContainer = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto 10vw;
    margin-bottom: auto;
`

type FiltrationFunction = (arg0: FullUserStats[]) => FullUserStats[]

const AllUserStatsTable = ({ stats }: { stats: FullUserStats[] }) => {
    const [filtrationFunction, setFiltrationFunction] = useState<FiltrationFunction>(() => (s: FullUserStats[]) => s)

    const sorted = _.sortBy(stats, (s) => -1 * s.reviews.length).filter((x) => x.reviews.length > 0)
    const maxDays = newDate().diff(START_DATE, 'days') + 1
    const filtered = filtrationFunction(sorted) //sorted.filter((x) => filtrationFunction(x))

    return (
        <UserRowsContainer>
            <UserFiltrationControls
                setFiltrationFunction={(f: FiltrationFunction) => {
                    setFiltrationFunction(() => f) // deal with react setState accepting a function :/
                }}
            ></UserFiltrationControls>
            {filtered.map((oneUserStats) => (
                <UserRow key={oneUserStats.user.id} oneUserStats={oneUserStats} maxDays={maxDays}></UserRow>
            ))}
        </UserRowsContainer>
    )
}

const UserFiltrationControls = ({ setFiltrationFunction }: { setFiltrationFunction: (arg0: FiltrationFunction) => void }) => {
    const [allowOneDay, setAllowOneDay] = useState<boolean>(false)
    const [sortByNewest, setSortByNewest] = useState<boolean>(false)

    useEffect(() => {
        setFiltrationFunction((allstats: FullUserStats[]): FullUserStats[] => {
            const isOneDay = (stats: FullUserStats): boolean => {
                const separateDays = _(stats.reviews)
                    .groupBy((r) => toGlobalSRSDay(newDate(r.reviewedAt), stats.user.settings.rolloverSecond))
                    .keys()
                    .value().length

                return separateDays === 1
            }

            let out = allstats

            if (sortByNewest) {
                out = _(out)
                    .orderBy((s) => s.user.createdTime, 'desc')
                    .value()
            }

            if (!allowOneDay) {
                out = out.filter((x) => !isOneDay(x))
            }

            return out
        })
    }, [allowOneDay, sortByNewest]) // if you add setFiltrationFunction here the page becomes unusuable, lol

    return (
        <UserFiltrationControlsContainer>
            <FormControlLabel
                control={<Switch value={sortByNewest} onChange={(e) => setSortByNewest(e.target.checked)}></Switch>}
                label="Sort by newest users"
            ></FormControlLabel>
            <FormControlLabel
                control={<Switch value={allowOneDay} onChange={(e) => setAllowOneDay(e.target.checked)}></Switch>}
                label="Single day users?"
            ></FormControlLabel>
        </UserFiltrationControlsContainer>
    )
}

const UserFiltrationControlsContainer = styled.div`
    display: grid;
    justify-content: end;
    align-content: center;
    grid-auto-flow: column;
`

const START_DATE = newDate('2021-05-01T00:00:00.000Z') //new Date(2021, 5, 1)

const UserRow = ({ oneUserStats, maxDays }: { oneUserStats: FullUserStats; maxDays: number }) => {
    const user = oneUserStats.user
    const history = useHistory()
    const reasonableReviews = labelOutliers(oneUserStats.reviews, (x) => x.totalTimeMS)
    const days: Record<number, FullReviewInfo[]> = useMemo(() => {
        const days = _(reasonableReviews)
            .groupBy((revInfo) => toUserSRSDay(newDate(revInfo.reviewedAt), user))
            .value()
        return days
    }, [oneUserStats, reasonableReviews])

    const currentDay = toUserSRSDay(newDate(), oneUserStats.user)

    //const assignmentDecisions = { meta: { news: 0, dues: 0 } }
    //const times = { New: 0, Learning: 0, Memorized: 0 }
    //const minutes = oneUserStats.courses.map((c: { minutes: number }) => c.minutes).join(' | ')

    //const { assignmentDecisions, times, minutes } = {
    //    assignmentDecisions: { meta: {} },
    //    times: {},
    //    minutes: 0,
    //    ...(oneUserStats.courses[0] || {}),
    //}

    const { assignmentDecisions, times, minutes } = oneUserStats.courses[0] || {
        assignmentDecisions: { meta: {} },
        times: {},
        minutes: 0,
    }

    const meta = assignmentDecisions.meta //.map((x: any) => x.meta)

    const startPaddingDays = maxDays - currentDay

    //console.log('user row days', days)

    return (
        <UserRowContainer>
            <DaySequenceContainer>
                {_.range(-startPaddingDays, currentDay + 1).map((dayIndex) => (
                    <OneDay key={dayIndex} reviewsOnDay={days[dayIndex]}></OneDay>
                ))}
                <EndOfRowInfo>
                    <p>
                        {meta.news} / {meta.dues}
                    </p>
                    <p>
                        {times.New?.toFixed(0)} / {times.Learning?.toFixed(0)} / {times.Memorized?.toFixed(0)}
                    </p>
                    <p>{minutes}m</p>
                </EndOfRowInfo>
            </DaySequenceContainer>
            <Button
                onClick={() => {
                    window.open(`/usage/${oneUserStats.user.id}`)
                    //history.push()
                }}
            >
                <Name>{oneUserStats.user.displayName}</Name>
            </Button>
        </UserRowContainer>
    )
}

const EndOfRowInfo = styled.div`
    width: 140px;
    height: 70px;
    border-radius: 5px;
    display: grid;
    align-content: center;
    justify-content: center;
    font-size: 8pt;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    justify-items: center;
    border: 1px lightgrey solid;
`

const DaySequenceContainer = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 5px;
    align-content: start;
`

const Name = styled.div`
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
`

const OneDay = ({ reviewsOnDay }: { reviewsOnDay: (FullReviewInfo & { outlier?: boolean })[] | null }) => {
    if (reviewsOnDay) {
        const correctCount = reviewsOnDay.filter((x) => x.answer === AnswerType.Remembered).length
        const incorrectCount = reviewsOnDay.filter((x) => x.answer === AnswerType.Forgot).length
        const accuracy = correctCount / (correctCount + incorrectCount)
        const newCount = reviewsOnDay.filter((x) => x.beforeStatus === CardStatus.New).length

        const reviews = reviewsOnDay.length

        const totalTimeMS = _(reviewsOnDay)
            .filter((x) => !x.outlier)
            .map((x) => _.min([x.totalTimeMS, 60 * 1000]))
            .sum()

        const totalTimeMins = Math.round(totalTimeMS / 60000)
        const averageTimePerRev = Math.round(totalTimeMS / 1000 / reviews)

        const firstSeenTodayIDs = reviewsOnDay.filter((r) => r.beforeStatus === 'New').map((r) => r.cardId)
        const newReviews = reviewsOnDay.filter((r) => firstSeenTodayIDs.includes(r.cardId))
        const newAccuracy = newReviews.filter((x) => x.answer === AnswerType.Remembered).length / newReviews.length
        const suspendPercent = reviewsOnDay.filter((x) => x.answer === AnswerType.Suspended).length

        //const maxIndex = _.max(reviewsOnDay.map((r) => r.))

        //const hadProblem = reviewsOnDay.map((r) => r.cardId).includes('dd475d12494bdec8' as CardID)

        // `hsl(${accuracy * 100}, ${reviews}%, 50%)`
        return (
            <DayCell color={`hsl(${accuracy * 100}, ${totalTimeMins * 2}%, 50%)`}>
                <div>{Math.round(accuracy * 100)}%</div>
                <div>{reviews}r</div>
                <div>{totalTimeMins}m</div>
                <div>{averageTimePerRev}s/r</div>
                <div>{newCount}n</div>
                <div>{Math.round(newAccuracy * 100)}n%</div>
                <div>{Math.round(suspendPercent)}s</div>
            </DayCell>
        )
    } else {
        return <DayCell color="hsl(0, 0%, 50%)"></DayCell>
    }
}

const DayCell = styled.div.attrs((props) => ({
    style: {
        backgroundColor: props.color,
    },
}))`
    width: 70px;
    height: 70px;
    border-radius: 5px;
    display: grid;
    align-content: center;
    justify-content: center;
    font-size: 8pt;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    justify-items: center;
`

export const TopLevelMultiUserStats = (props: any) => (
    <MultiUserStats whitelistIDs={props.match.params.whitelist?.split(',').filter((x: string) => x !== '')}></MultiUserStats>
)

export const MultiUserStats = ({ whitelistIDs }: { whitelistIDs: string[] }) => {
    const { error, loading, data } = useQuery(GET_ALL_USERS_FULL_STATS)

    if (error || loading || !data) {
        return <DataNotLoaded {...{ loading, error, data }} />
    }

    //if (data.getGlobalStatsLegacy === 'Not allowed.') {
    //    return <DataNotLoaded {...{ error: 'Access Denied', loading: false, data: false }} />
    //}

    const parsed: FullUserStats[] = JSON.parse(data.getAllUserStats)

    if (!whitelistIDs || whitelistIDs.length === 0) {
        return <AllUserStatsTable stats={parsed} />
    } else {
        return (
            <AllUserStatsTable
                stats={parsed.filter((userStats) =>
                    _.some(whitelistIDs.map((w) => userStats.user.displayName.toLowerCase().includes(w.toLowerCase())))
                )}
            />
        )
    }
}
