import { gql, useMutation } from '@apollo/client'
import { Button } from '@material-ui/core'
import amplitude from 'amplitude-js'
//import LogRocket from 'logrocket'
import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { ClientUser } from './client_types'
import { GQLUpdateSettingsMutation } from './generated/client-graphql-types'
import { backendURL } from './shared/sharedutil'

const UPDATE_SETTINGS = gql`
    mutation updateSettings($settings: UserSettingsInput!) {
        updateSettings(settings: $settings) {
            success
        }
    }
`

export const LoginButton = () => (
    <Button color="inherit" href={`${backendURL}/login`} onClick={doLogin()}>
        Sign In
    </Button>
)

export const doLogin = (redirectTo?: string) => () => {
    let finalRedirect = redirectTo || window.location.pathname
    finalRedirect = finalRedirect.replace('/try', '/preview')
    window.localStorage['redirect_url'] = finalRedirect
    window.location.href = `${backendURL}/login`
}

export const Login = () => {
    const user = useContext(UserContext)
    const [updateSettings, _] = useMutation<GQLUpdateSettingsMutation>(UPDATE_SETTINGS)

    useEffect(() => {
        idenUser(user)
        if (user) {
            updateSettings({
                variables: {
                    settings: {
                        rolloverSecond: (4 + new Date().getTimezoneOffset() / 60) * 60 * 60, //4am in UTC
                        tzOffsetSeconds: new Date().getTimezoneOffset() * 60, // this might be like -1.75 for some reason.
                    },
                },
            })
        }
    }, [user?.displayName, updateSettings])

    if (!user) {
        return <LoginButton />
    } else {
        return <ProfilePicture alt="profile picture" src={user.picture} referrerPolicy="no-referrer" />
    }
}

export const UserContext = React.createContext<ClientUser | null>(null)

const idenUser = (user: ClientUser | null) => {
    setTimeout(() => {
        if (user && user.displayName) {
            const fs = window && (window as any).FS
            const mixpanel = window && (window as any).mixpanel
            if (fs && fs.setUserVars) {
                fs.identify(user.id)
                fs.setUserVars({ displayName: user.displayName })
            }

            /*
            const discordId = window.localStorage.getItem('quantizedDeckInfo')
            LogRocket.identify(user.id, {
                name: user.displayName,
                email: user.email!,
                discordId: discordId || false,
            })
            */

            const identify = new amplitude.Identify().setOnce('displayName', user.displayName)
            amplitude.getInstance().setUserId(user.id)
            amplitude.getInstance().identify(identify)

            // we don't use mixpanel
            if (mixpanel) {
                mixpanel.identify(user.id)
                mixpanel.people.set({
                    $name: user.displayName,
                    USER_ID: user.id,
                    $email: user.email,
                })
            }
        }
    }, 1000)
}

export const ProfilePicture = styled.img`
    border-radius: 50%;
    height: 32px;
`
