import { gql, useMutation, useQuery } from '@apollo/client'
import { Accordion, AccordionDetails, AccordionSummary, Divider, IconButton } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import _ from 'lodash'
import ChevronDown from 'mdi-material-ui/ChevronDown'
import React, { useContext, useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { ClientCard } from '../client_types'
//import { useGetMoreCards } from './Card'
import { DeckImage } from '../DeckItem'
import { ALL_DECKS, GET_DECK_BY_ID } from '../DiscoverPage'
import {
    GQLAllDecksQuery,
    GQLGetDeckByIdQuery,
    GQLGetReviewsQuery,
    GQLUnlockPermissionMutation,
} from '../generated/client-graphql-types'
import { UserContext } from '../Login'
import { GET_REVIEWS } from '../reviewer/DeckReviewer'
import { numLeft } from '../shared/sharedutil'
import { APIErrorStrings, CardStatus, CardType, ChangelogItem, Deck, TagID } from '../shared/types'
import { DeckStats } from '../Stats'
import { DataNotLoaded, useQueryParams } from '../Utils'
import { ActionButton } from './DeckPreviewActionButton'

const DeckPreviewPresenter = styled.div`
    padding: 2vh 4vw;
    display: grid;
    width: 100%;
    max-width: 1000px;
    grid-gap: 20px;
    margin: 0 auto auto 0;
`
//justify-self: start;

const DeckPreviewHeaderSection = styled.div`
    display: grid;
    grid-template-columns: minmax(300px, 15%) 1fr;
    @media (max-width: 481px) {
        grid-template-columns: 1fr;
    }
    grid-gap: 2vw;
`

const DeckPreviewHeaderItems = styled.div`
    display: grid;
    grid-template-rows: auto auto auto 1fr;
    grid-gap: 10px;
    justify-content: start;
    max-width: 1000px;
    width: 100%;
`

const DeckPreviewDescriptionSection = styled.div`
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #333333;
    max-width: 90vw;
`

const DeckPreviewTitle = styled.p`
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
`

const DeckPreviewAuthor = styled.p`
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
`

const DeckPreviewSubtitle = styled.p`
    font-family: Roboto;
    font-size: 18px;
    color: #818181;
`

export const useReviews = (deckID: string) => {
    const me = useContext(UserContext)

    const { error, loading, data, refetch } = useQuery<GQLGetReviewsQuery>(GET_REVIEWS, {
        variables: { tagID: deckID },
        errorPolicy: 'all',
        skip: !me,
    })

    return {
        error,
        loading,
        data: {
            // wtf
            toReview: data?.toReview
                .filter((x: any) => x)
                .map((x) => ({
                    ...x,
                    type: x.type as unknown as CardType,
                    status: x.status as unknown as CardStatus,
                })) as ClientCard[],
        },
        refetch,
    }
}

const UNLOCK_PERMISSION = gql`
    mutation unlockPermission($tagID: ID!, $secret: String!) {
        unlockPermission(tagID: $tagID, secret: $secret) {
            id
        }
    }
`

export const useSecrets = (id: string) => {
    const secret = JSON.parse(window.localStorage.getItem('secrets') || '{}')[id]

    const setSecret = (updated: string) => {
        const stored = JSON.parse(window.localStorage.getItem('secrets') || '{}')
        stored[id] = updated
        window.localStorage.setItem('secrets', JSON.stringify(stored))
    }

    return [secret, setSecret]
}

const StudySettingsContainer = styled.div`
    display: grid;
    justify-content: start;
    grid-template-columns: auto auto;
    align-items: center;
    grid-gap: 5px;
`

const DeckPreview = ({
    deck,
    stats,
    refetchAllDecks,
}: {
    deck: Deck
    stats: DeckStats | undefined
    refetchAllDecks: () => Promise<any>
}) => {
    const history = useHistory()
    //const query = useQueryParams() as { key?: string }

    const { secret: urlSecret } = useParams<{ secret: string }>()

    const [unlockPermission] = useMutation<GQLUnlockPermissionMutation>(UNLOCK_PERMISSION)

    const { error, loading, data, refetch: refetchDeck } = useReviews(deck.id) // useQuery(GET_REVIEWS, { variables: { tagID: deck.id }, errorPolicy: 'all' })
    const user = useContext(UserContext)

    const [storedSecret, setStoredSecret] = useSecrets(deck.id)

    useEffect(() => {
        ;(async () => {
            if (user && urlSecret) {
                await unlockPermission({ variables: { secret: urlSecret, tagID: deck.id } })
                await refetchAllDecks()
            } else if (!user && urlSecret) {
                setStoredSecret(urlSecret)
            } else if (user && storedSecret && deck.locked) {
                await unlockPermission({ variables: { secret: storedSecret, tagID: deck.id } })
                await refetchAllDecks()
            }

            history.replace({ pathname: window.location.pathname.split('/unlock')[0] })
        })()
    }, [urlSecret, deck])

    let left = 0
    if (data && data.toReview && user) {
        left = numLeft(data.toReview, user.settings.rolloverSecond) // 2
    }

    if (error) {
        console.error(error)
    }

    return (
        <DeckPreviewPresenter>
            <DeckPreviewHeaderSection>
                <DeckImage src={deck.image || undefined}></DeckImage>
                <DeckPreviewHeaderItems>
                    <DeckPreviewTitle>{deck.title}</DeckPreviewTitle>
                    {deck.author !== 'Robert Cunningham' && <DeckPreviewAuthor>{deck.author}</DeckPreviewAuthor>}
                    <DeckPreviewSubtitle>{deck.flavor}</DeckPreviewSubtitle>
                    <StudySettingsContainer>
                        <ActionButton
                            {...{ user, deck, loading, left }}
                            locked={!!deck.locked}
                            refetch={async () => {
                                await refetchDeck()
                                await refetchAllDecks()
                            }}
                        ></ActionButton>
                        {deck.learning ? <SettingsButton></SettingsButton> : <div></div>}
                    </StudySettingsContainer>
                </DeckPreviewHeaderItems>
            </DeckPreviewHeaderSection>
            <Divider></Divider>
            {deck.changelog && (
                <>
                    <ChangelogBlock changelog={deck.changelog}></ChangelogBlock>
                    <Divider></Divider>
                </>
            )}
            {deck?.description?.includes('<li>') ? (
                <DeckPreviewDescriptionSection
                    dangerouslySetInnerHTML={{ __html: deck.description + '' }}
                ></DeckPreviewDescriptionSection>
            ) : (
                <DeckPreviewDescriptionSection>{deck.description}</DeckPreviewDescriptionSection>
            )}
            <DeckPreviewDescriptionSection>
                {deck.faq?.map(({ question, answer }: { question: string; answer: string }) => (
                    <FAQBlock key={question} header={question} content={answer} />
                ))}
            </DeckPreviewDescriptionSection>
        </DeckPreviewPresenter>
    )
}
//<Divider></Divider>
/*{!isMobile && stats && _.size(stats.history.past) > 0 && <Stats stats={stats} />}*/
//{console.log('t', deck.title, stats)}{(!stats || _.size(stats.history.past) === 0) && deck.title === "Rust" && <CardPreview />}

const ChangelogBlock = ({ changelog }: { changelog: ChangelogItem[] }) => {
    const [open, setOpen] = useState<boolean>(false)

    const mostRecentChange = _.last(changelog)

    return (
        <ChangelogContainer>
            <ChangelogMainColumn>
                <ChangelogWhatsNew>What's New</ChangelogWhatsNew>
                {mostRecentChange?.info}
            </ChangelogMainColumn>
            <ChangelogMeta>
                <ChangelogMetaLine>{mostRecentChange?.date}</ChangelogMetaLine>
                <ChangelogMetaLine>Version {mostRecentChange?.version}</ChangelogMetaLine>
            </ChangelogMeta>
        </ChangelogContainer>
    )
}

const ChangelogMetaLine = styled.div`
    text-align: right;
    display: grid;
`

const ChangelogMeta = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    align-content: start;
`

const ChangelogMainColumn = styled.div``

const ChangelogWhatsNew = styled.div`
    font-weight: 700;
    font-size: 22pt;
`

const ChangelogContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
`

const SettingsButton = () => {
    const history = useHistory()
    const { deckID } = useParams<{ deckID: string }>()

    return (
        <IconButton onClick={() => history.push(`/deck/${deckID}/settings`)}>
            <SettingsIcon></SettingsIcon>
        </IconButton>
    )
}

export const TopLevelDeckPreviewer = () => {
    const { deckID } = useParams<{ deckID: TagID }>()

    const {
        error,
        loading,
        data,
        refetch: refetchAllDecks,
    } = useQuery<GQLGetDeckByIdQuery>(GET_DECK_BY_ID, {
        variables: { deckID: deckID },
    })

    //const { data: stats, refetch } = useQuery<{ getStatsForID: string }>(GET_STATS, { variables: { tagID: id } })

    //useEffect(() => {
    //    refetch()
    //}, [refetch])

    //const processedStats = stats?.getStatsForID ? JSON.parse(stats.getStatsForID) : undefined

    if (error || loading || !data) {
        return <DataNotLoaded {...{ loading, error, data }} />
    }

    if (!data.getDeckById) {
        return <DataNotLoaded loading={false} data={undefined} error={'This deck does not exist.'}></DataNotLoaded>
    }

    const deck = data.getDeckById

    return (
        <DeckPreview
            refetchAllDecks={refetchAllDecks}
            /*stats={processedStats}*/
            stats={undefined}
            //@ts-ignore
            deck={deck}
        ></DeckPreview>
    )

    // const cards = data.getTagByID.cards
    // cards.map(parseCard)

    // return (
    //     <MoreInfoContainer>
    //         {cards.map(c => (
    //             <Card isCardFlipped={true} key={c.id} {...c} {...c.data} />
    //         ))}
    //     </MoreInfoContainer>
    // )
}

const AccordionInternal = styled.div`
    display: grid;
`

const FAQBlock = ({ header, content }: { header: string; content: string }) => (
    <Accordion elevation={1}>
        <AccordionSummary expandIcon={<ChevronDown />}>{header}</AccordionSummary>
        <AccordionDetails>
            <AccordionInternal>
                <ReactMarkdown>{content}</ReactMarkdown>
            </AccordionInternal>
        </AccordionDetails>
    </Accordion>
)
