import { gql, useQuery } from '@apollo/client'
import { Avatar, Button, List, ListItem, ListItemAvatar, ListItemText, Paper, Switch, TextField } from '@material-ui/core'
import { ChessPawn, ChessKnight, ChessRook, ChessKing } from 'mdi-material-ui'
import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { CommitmentSelectorInternal, GET_COMMITMENT_INFO, useMutateCourseSettings } from '../DeckPreview/DeckSettings'
import { GQLGetCommitmentInfoQuery } from '../generated/client-graphql-types'
import { useCourseSettings } from '../reviewer/DeckReviewer'
import { averageTimesToNewCount } from '../shared/sharedutil'
import { CardStatus, Seconds, TagID } from '../shared/types'
import { DataNotLoaded } from '../Utils'
import { useResetToReview } from './Advanced'
import { StandardSetting } from './ResetDeck'

export const DeckSettingsNewCards = () => {
    const { deckID } = useParams<{ deckID: TagID }>()
    return <DailyTimeSelector deckID={deckID}></DailyTimeSelector>
}

export const DailyTimeSelectorPresenter = ({
    onSelect,
    additionalInfo,
    selected,
}: {
    selected: number | undefined
    onSelect: (arg0: { minutes: number }) => void
    additionalInfo: (arg0: number | undefined) => any
}) => {
    const { deckID } = useParams<{ deckID: string }>()
    const history = useHistory()
    const options = [
        {
            value: 15,
            name: 'Easy (15m)',
            text: 'You like taking life slow & looking at the scenery. Do 15 minutes of reviews each day.',
            icon: <ChessPawn />,
        },
        {
            value: 30,
            name: 'Normal (30m)',
            text: "You're indecisive and always end up picking the middling option. Do 30 minutes of reviews each day.",
            icon: <ChessKnight />,
        },
        {
            value: 45,
            name: 'Intense (45m)',
            text: "Yeah, it's pretty intense. Do 45 minutes of reviews each day.",
            icon: <ChessRook />,
        },
        {
            value: 60,
            name: 'Insane (60m)',
            text: 'You fight alligators with your bare hands in your free time. Do 60 minutes of reviews each day.',
            icon: <ChessKing />,
        },
        /*
        {
            value: 0,
            name: "Don't Study",
            text: "Thanks, but I'll pass on studying this stuff every day.",
            icon: <Cancel />,
        },
        */
    ]

    const EXPLANATION_TEXT =
        "Quantized schedules your workload so that if you use it consistently you'll spend roughly the same amount of time each day doing reviews."
    let explanation = `${EXPLANATION_TEXT} Choose how much time you to commit per day to this deck.`
    if (selected === undefined) {
        explanation = `Hey! We don't know how much time you want to spend per day for this deck. Help us by choosing an option below. ${EXPLANATION_TEXT}`
    }

    return (
        <CommitmentSelectorInternal>
            <LightText>{explanation}</LightText>
            <List>
                {options.map((o) => (
                    <ListItem selected={o.value === selected} key={o.name} button onClick={() => onSelect({ minutes: o.value })}>
                        <ListItemAvatar>
                            <Avatar>{o.icon}</Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={o.name} secondary={o.text} />
                    </ListItem>
                ))}
            </List>
            {additionalInfo(selected)}
            <LightText>Any changes will take effect tomorrow.</LightText>
            <DoneButton variant="outlined" onClick={() => history.push(`/preview/${deckID}`)}>
                Done
            </DoneButton>
        </CommitmentSelectorInternal>
    )
}

const DoneButton = styled(Button)`
    margin: 20px auto;
`

const LightText = styled.p`
    color: rgba(0, 0, 0, 0.75);
    font-family: Roboto;
    font-size: 16px;
`

// <InstructionParagraph></InstructionParagraph>
export const DailyTimeSelector = ({ deckID, onDone }: { deckID: TagID; onDone?: ({ minutes }: { minutes: number }) => void }) => {
    const { data, error, loading } = useQuery<GQLGetCommitmentInfoQuery>(GET_COMMITMENT_INFO, {
        variables: { courseID: deckID },
    })

    const mutateCourseSettings = useMutateCourseSettings(deckID)
    const { error: error2, loading: loading2, data: settingsData } = useCourseSettings(deckID)

    if (error || loading || !data || error2 || loading2 || !settingsData) {
        return <DataNotLoaded {...{ loading, error, data }} />
    }

    //const minutes = data.getCourse?.settings?.minutes
    const minutes = settingsData?.minutes

    const averageTimeByType = JSON.parse(data.getTagByID.averageTimeByType!) as Record<CardStatus, Seconds>

    const minutesToExample = (mins: number) => {
        return averageTimesToNewCount(averageTimeByType, mins * 60)
    }

    return (
        <DailyTimeSelectorPresenter
            selected={minutes || undefined}
            additionalInfo={(mins: number | undefined) => {
                if (mins === undefined) {
                    return <LightText>Select an option to see your projected workload.</LightText>
                }
                const example = minutesToExample(mins)
                return (
                    <LightText>
                        Your current review speed is roughly {Math.round(averageTimeByType[CardStatus.New])} seconds per new card
                        and {Math.round(averageTimeByType[CardStatus.Memorized])} seconds per memorized card. If you select this
                        option, we would assign you around <strong>{example.news} news</strong> tomorrow.
                    </LightText>
                )
            }}
            onSelect={async ({ minutes }: { minutes: number }) => {
                await mutateCourseSettings({ minutes })
                if (onDone) onDone({ minutes })
            }}
        ></DailyTimeSelectorPresenter>
    )
}
