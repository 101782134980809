import _ from 'lodash'
import MaterialTable from 'material-table'
import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useDeckReviews } from '../Admin/TopLineMetrics'
import { DataNotLoaded } from '../Utils'
import OpenInNewIcon from 'mdi-material-ui/OpenInNew'
import UndoIcon from 'mdi-material-ui/Undo'
import { gql, useMutation, useQuery } from '@apollo/client'
import { ALL_CARD_FIELDS_FRAGMENT } from '../fragments'
import { GQLBrowseableCardsQuery, GQLUndoLastReviewForCardMutation } from '../generated/client-graphql-types'
import { suspendInterval } from '../shared/sharedutil'
import { newDate } from '../shared/times'

const BROWSEABLE_CARDS = gql`
    ${ALL_CARD_FIELDS_FRAGMENT}
    query browseableCards($tagID: ID!) {
        browseableCards(tagID: $tagID) {
            ...allCardFields
        }
    }
`

const UNDO_REVIEW = gql`
    ${ALL_CARD_FIELDS_FRAGMENT}
    mutation undoLastReviewForCard($cardID: ID!) {
        undoLastReviewForCard(cardID: $cardID) {
            ...allCardFields
        }
    }
`

export const DeckSettingsCards = () => {
    const { deckID } = useParams<{ deckID: string }>()

    //const { error, loading, data } = useDeckReviews(deckID)
    const { error, loading, data } = useQuery<GQLBrowseableCardsQuery>(BROWSEABLE_CARDS, {
        variables: {
            tagID: deckID,
        },
    })

    const [undoReview] = useMutation<GQLUndoLastReviewForCardMutation>(UNDO_REVIEW)

    if (!data || loading || error) {
        return <DataNotLoaded {...{ error, loading, data }}></DataNotLoaded>
    }

    const allCards = data.browseableCards

    return (
        <CardBrowserContainer>
            <MaterialTable
                options={{
                    showTitle: false,
                    search: false,
                    padding: 'dense',
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    pageSizeOptions: [],
                }}
                localization={{
                    header: { actions: '' },
                }}
                components={{
                    Toolbar: () => <div></div>,
                }}
                columns={[
                    { title: 'ID', field: 'id' },
                    { title: 'Index', field: 'index' },
                    { title: 'Suspended', field: 'suspended' },
                    { title: 'Date Suspended', field: 'dateSuspended' },
                ]}
                data={allCards.map((c) => ({
                    id: c.id,
                    index: c.index,
                    suspended: c.interval === suspendInterval.asSeconds() ? 'Yes' : 'No',
                    due: c.due,
                    dateSuspended:
                        c.interval === suspendInterval.asSeconds() ? newDate(c.due!).subtract(suspendInterval).toString() : '',
                }))}
                actions={[
                    {
                        icon: () => <OpenInNewIcon style={{ opacity: 0.54 }}></OpenInNewIcon>,
                        tooltip: 'View this card',
                        onClick: (event, rowData) => {
                            //@ts-ignore
                            const url = `/view/card/${rowData.id as string}`
                            window.open(url, '_blank')
                        },
                    },
                    (rowData) => ({
                        icon: () => <UndoIcon style={{ opacity: 0.54 }}></UndoIcon>,
                        tooltip: 'Unsuspend this card',
                        hidden: rowData.dateSuspended === '',
                        onClick: (event, rowData) => {
                            //@ts-ignore
                            //const url = `/view/card/${rowData.id as string}`
                            //window.open(url, '_blank')
                            undoReview({ variables: { cardID: rowData.id as string } })
                        },
                    }),
                ]}
            ></MaterialTable>
        </CardBrowserContainer>
    )
}

const CardBrowserContainer = styled.div`
    margin: 20px;
`
