import ButtonBase from '@material-ui/core/ButtonBase'
import Paper from '@material-ui/core/Paper'
import React from 'react'
import styled from 'styled-components'

export const DeckInternal = styled.div`
    display: grid;
    min-width: 240px;
    width: 100%;
    text-align: left;
    font-family: Roboto;
`

export const DeckImage = styled.img`
    width: 100%;
    height: 185px;
    object-fit: cover;
    object-position: center;
    max-width: 350px;
    margin: auto;
`

const DeckInfo = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 3em auto;
    padding: 12px;
    grid-gap: 12px;
    width: 100%;
`

const DeckTitle = styled.p`
    font-weight: 500;
    font-size: 20px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`

const DeckMiniTextContainer = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    width: 100%;
`

const DeckMiniText = styled.p`
    font-weight: 400;
    display: grid;
    grid-column-template: auto 1fr auto;
    color: rgba(0, 0, 0, 0.4);
`
//font-size: 14px;

const DeckFlavor = styled.p`
    color: rgba(0, 0, 0, 0.6);
`

const GridPaper = styled(Paper)`
    display: grid;
`

export const DeckItemPresenter = (
    {
        image,
        title,
        author,
        flavor,
        mini_left,
        mini_right,
        onClick,
    }: {
        image?: string | null
        title: string
        author: string | null
        flavor: string | null
        mini_left?: string
        mini_right?: string
        onClick: any
    } //: React.MouseEvent<HTMLButtonElement>: void }) => (
) => (
    <GridPaper elevation={2} square={false}>
        <ButtonBase onClick={onClick}>
            <DeckInternal>
                <DeckImage src={image || ''} alt="" />
                <DeckInfo>
                    <DeckTitle>{title}</DeckTitle>
                    <DeckFlavor> {flavor || ''}</DeckFlavor>
                    <DeckMiniTextContainer>
                        <DeckMiniText>{mini_left}</DeckMiniText>
                        <div> </div>
                        <DeckMiniText>{mini_right}</DeckMiniText>
                    </DeckMiniTextContainer>
                </DeckInfo>
            </DeckInternal>
        </ButtonBase>
    </GridPaper>
)
