import { backendURL } from './shared/sharedutil'

export const get = (path, method, body) => {
    return fetch(`${backendURL}/${path}`, getOptions(method, body)).then((resp) => {
        if (resp.status === 401) {
            console.log('Unauthorized.')
            window.location.replace(`${backendURL}/login`)
        } else {
            return resp
        }
    })
}

const getOptions = (method, body) => {
    if (!method || method === 'GET') {
        return { credentials: 'include' }
    } else if (method === 'POST') {
        return {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        }
    } else {
        throw new Error('Unrecognized method.')
    }
}
