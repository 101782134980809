import { gql, useMutation, useQuery } from '@apollo/client'
import { Button, IconButton, MenuItem, Select, TextField } from '@material-ui/core'
import { ThumbUpSharp } from '@material-ui/icons'
import _ from 'lodash'
import React, { useContext, useState } from 'react'
import ReactMarkdownInternal from 'react-markdown/with-html'
import styled from 'styled-components'
import { DrawerContext } from '../App'
import { ClientCard, ClientComment } from '../client_types'
import { GQLGetFProfilesQuery, GQLWriteCommentMutation } from '../generated/client-graphql-types'
import { UserContext } from '../Login'
import { newDate, newDuration } from '../shared/times'

const CommentSectionContainer = styled.div<{ drawer: boolean }>`
    display: grid;
    max-height: 5vw;
    overflow-y: visible;
    /*margin-top: 24px;*/
    margin-top: 4px;
    width: 1000px;
    max-width: ${(props) => (props.drawer ? 'calc(100vw - 240px)' : '100vw')};
    padding: 5px;
`

const CommentSectionContainerHidden = styled.div<{ drawer: boolean }>`
    display: grid;
    max-height: 0vw;
    overflow-y: visible;
    width: 1000px;
    max-width: ${(props) => (props.drawer ? 'calc(100vw - 240px)' : 'calc(100vw)')};
}

`
const CommentSectionContainerHiddenSpacer = styled.div<{ drawer: boolean }>`
    display: grid;
    width: 1000px;
    max-width: ${(props) => (props.drawer ? 'calc(100vw - 240px)' : 'calc(100vw)')};
    margin-top: 4px;
`

const CommentCreationContainer = styled.div`
    display: grid;
    margin-bottom: 24px;
    grid-template-columns: auto 1fr;
    grid-gap: 16px;
`

const CommentContainer = styled.div`
    display: grid;
    margin-bottom: 16px;
    grid-template-columns: auto 1fr;
    grid-gap: 16px;
    max-width: 95vw;
`

const CommentPicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
`

const CommentMainColumn = styled.div`
    display: grid;
`

const CommentHeader = styled.div`
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    grid-gap: 3px;
    align-items: end;
`

const CommentTime = styled.div`
    font-size: 12px;
    font-weight: 400;
    color: #666;
`

const CommentName = styled.div`
    font-size: 13px;
    font-weight: 500;
`

const CommentContents = styled.div`
    font-size: 14px;
    overflow: hidden;
    text-align: justify;
    overflow-wrap: break-word;
`

const CommentButtons = styled.div`
    display: grid;
    grid-template-columns: 20px auto;
`

const CommentCreationButtons = styled.div`
    display: grid;
    grid-template-columns: 1fr auto auto;
    width: 100%;
    grid-gap: 8px;
`

const SUBMIT_COMMENT = gql`
    mutation writeComment($cardId: ID!, $contents: String!, $adminID: String) {
        writeComment(cardId: $cardId, contents: $contents, adminID: $adminID) {
            id
            comments {
                author {
                    displayName
                    picture
                    id
                }
                contents
                likes
                timeCreated
            }
        }
    }
`

export const CommentsSection = ({
    comments,
    //machineComments,
    cardId,
    card,
}: {
    comments: ClientComment[]
    //machineComments: MachineComment[]
    cardId: string
    card: ClientCard
}) => {
    const me = useContext(UserContext)
    const drawerOpen = useContext(DrawerContext)

    const myComments = _(comments)
        .filter((c) => c.author?.id === me?.id)
        .sort((c) => newDate(c.timeCreated).unix())
        .reverse()
        .value()
    const otherComments = _(comments)
        .filter((c) => c.author?.id !== me?.id)
        //.sort((c) => c.likes)
        .reverse()
        .value()
    const orderedComments = _.concat(myComments, otherComments)

    if (!me && orderedComments.length === 0) {
        return null
    }

    return (
        //<CommentSectionContainer drawer={!!drawerOpen}>
        <CommentSectionContainerHidden drawer={!!drawerOpen}>
            <CommentSectionContainerHiddenSpacer drawer={!!drawerOpen}>
                {orderedComments.map((c, i) => (
                    <Comment key={i} comment={c}></Comment>
                ))}
                {/*machineComments.map((c) => (
                <MachineCommentComponent key={c.contents} comment={c}></MachineCommentComponent>
            ))*/}
                {me && <CommentCreationBox card={card} cardId={cardId}></CommentCreationBox>}
                <CommentContainer> </CommentContainer>
            </CommentSectionContainerHiddenSpacer>
        </CommentSectionContainerHidden>
    )
}

//const MachineCommentComponent = ({ comment }: { comment: MachineComment }) => {
//    return <Comment comment={comment as UserComment}></Comment>
//}

const Comment = ({ comment }: { comment: ClientComment }) => {
    const likeComment = () => console.log('liked comment')
    return (
        <CommentContainer>
            <CommentPicture src={comment.author?.picture} />
            <CommentMainColumn>
                <CommentHeader>
                    <CommentName>{comment.author?.displayName}</CommentName>
                    <CommentTime>{newDuration(newDate(comment.timeCreated).diff(newDate())).humanize(true)}</CommentTime>
                </CommentHeader>
                <CommentContents>
                    <ReactMarkdownInternal source={comment.contents}></ReactMarkdownInternal>
                </CommentContents>
                {false && (
                    <CommentButtons>
                        <IconButton style={{ fontSize: 16, color: '#999' }} onClick={likeComment}>
                            <ThumbUpSharp style={{ fontSize: 16 }}></ThumbUpSharp>
                        </IconButton>
                        <div></div>
                    </CommentButtons>
                )}
            </CommentMainColumn>
        </CommentContainer>
    )
}

const GET_F_PROFILES = gql`
    query getFProfiles {
        getFProfiles {
            id
            displayName
        }
    }
`

const AdminCommentControls = ({ selected, setSelected }: { selected: string; setSelected: (arg0: string) => void }) => {
    const { data } = useQuery<GQLGetFProfilesQuery>(GET_F_PROFILES)

    return (
        <div>
            <Select variant="outlined" value={selected} onChange={(e) => setSelected(e.target.value as string)}>
                {data?.getFProfiles.map((u) => (
                    <MenuItem key={u.id} value={u.id}>
                        {u.displayName}
                    </MenuItem>
                ))}
            </Select>
        </div>
    )
}

export const CommentCreationBox = ({ card, cardId }: { card: ClientCard & { comments: ClientComment[] }; cardId: string }) => {
    const [text, setText] = useState<string>('')
    const [adminID, setAdminID] = useState<string>('')

    const [isFocused, setIsFocused] = useState<boolean>(false)

    const [rawWriteComment] = useMutation<GQLWriteCommentMutation>(SUBMIT_COMMENT)

    const me = useContext(UserContext)

    const writeComment = async (contents: string) => {
        const newComment = {
            author: me,
            cardId,
            contents,
            likes: 0,
            timeCreated: newDate().toISOString(),
            timeModified: undefined,
        }

        const optimisticResponse = {
            ...card,
            comments: [newComment, ...card.comments].map((raw) => ({
                ...raw,
                timeCreated: raw.timeCreated,
            })),
        }
        //console.log('or', optimisticResponse)

        await rawWriteComment({
            variables: { cardId: cardId, contents, adminID: adminID },
            optimisticResponse: {
                __typename: 'Mutation',
                writeComment: {
                    __typename: 'Card',
                    ...optimisticResponse,
                },
            },
        })
    }

    return (
        <CommentCreationContainer>
            <CommentPicture src={me?.picture} />
            <TextField
                color="secondary"
                onFocus={() => setIsFocused(true)}
                /*onBlur={() => setIsFocused(false)}*/
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder={`Commenting as ${me?.displayName}`}
                multiline={true}
            ></TextField>
            {isFocused && (
                <>
                    <div></div>
                    <CommentCreationButtons>
                        {me?.email === 'robertvcunningham@gmail.com' ? (
                            <AdminCommentControls selected={adminID} setSelected={setAdminID}></AdminCommentControls>
                        ) : (
                            <div></div>
                        )}
                        <Button
                            onClick={() => {
                                setIsFocused(false)
                                setText('')
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={async () => {
                                await writeComment(text)
                                setText('')
                            }}
                            variant="contained"
                            color="secondary"
                        >
                            Comment
                        </Button>
                    </CommentCreationButtons>
                </>
            )}
        </CommentCreationContainer>
    )
}
