import { useQuery } from '@apollo/client'
import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { ME } from './App'
import { GQLMeQuery } from './generated/client-graphql-types'
import { UserContext } from './Login'
import { DataNotLoaded } from './Utils'

export const Landing = () => {
    const { data, loading, error } = useQuery<GQLMeQuery>(ME)

    if (!data || loading || error) {
        return <DataNotLoaded {...{ error, loading, data }}></DataNotLoaded>
    }

    const user = data.me

    if (user && !user?.settings?.needsDeckWelcome) {
        //logged in and has already started a deck.
        return <Redirect to="/study"></Redirect>
    } else {
        return <Redirect to="/browse"></Redirect>
    }
}
