import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(utc)
dayjs.extend(duration)
dayjs.extend(relativeTime)

export const newDate = dayjs.utc
export const newDuration = dayjs.duration
export const fromUnix = (...a0: Parameters<typeof dayjs.unix>) => dayjs.unix(...a0).utc()
export type DateType = dayjs.Dayjs
