import { gql, useQuery } from '@apollo/client'
import Button from '@material-ui/core/Button'
import React, { useState } from 'react'
import styled from 'styled-components'
import { GQLAllUsersQuery } from '../generated/client-graphql-types'
import { frontendURL } from '../shared/sharedutil'
import { User } from '../shared/types'
import { get } from '../util'
import { DataNotLoaded } from '../Utils'
import { ALL_USERS, UserSelectionContainer } from './BrowseUsers'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'

export const Impersonate = () => {
    type ImpersonateableUser = Pick<User, 'id' | 'displayName' | 'email'>

    const { error, loading, data } = useQuery<GQLAllUsersQuery>(ALL_USERS)
    const impersonate = (userId: string) => get('impersonate', 'POST', { userId })
    const [selected, setSelected] = useState<null | ImpersonateableUser>({ id: 'yo', displayName: 'asdf', email: 'asdf' })

    if (error || loading || !data) {
        return <DataNotLoaded {...{ loading, error, data }} />
    }

    const users = data.allUsers as ImpersonateableUser[]

    console.log(users)

    return (
        <ImpersonationContainer>
            <Autocomplete
                fullWidth={true}
                options={users}
                //value={selected}
                onChange={(e, newValue) => setSelected(newValue)}
                getOptionLabel={(u) => `${u.displayName} (${u.email})`}
                renderInput={(params) => <TextField {...params} label="Target User" variant="outlined"></TextField>}
            ></Autocomplete>
            <Button
                disabled={!selected}
                onClick={async () => {
                    await impersonate(selected?.id!)
                    window.location.replace(`${frontendURL}`)
                }}
                variant="outlined"
            >
                Impersonate
            </Button>
        </ImpersonationContainer>
    )
}

const ImpersonationContainer = styled.div`
    display: grid;
    margin: auto;
    width: 1000px;
    max-width: 70vw;
    grid-gap: 10px;
`

/*
            {users.map((u) => (
                <Button
                    key={u.id}
                    onClick={async () => {
                    }}
                    variant="contained"
                    color="primary"
                >
                    {u.displayName}
                </Button>
            ))}
            */
