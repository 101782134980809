import { gql, useQuery } from '@apollo/client'
import _ from 'lodash'
import React, { useContext } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { DeckInternal, DeckItemPresenter } from './DeckItem'
import { GQLAllDecksQuery } from './generated/client-graphql-types'
import { UserContext } from './Login'
import { Deck } from './shared/types'
import { DataNotLoaded } from './Utils'

export const DeckHolder = styled.div`
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: 20px;
    padding: 20px;
    margin: 0px 0px auto 0px;
`

const DECK_FRAGMENT = gql`
    fragment deckInfo on Deck {
        id
        title
        author
        description
        flavor
        image
        price
        changelog {
            date
            info
            version
        }
        tag {
            count
        }
        faq {
            question
            answer
        }
        externalURL
        permissions
        locked
    }
`

export const GET_DECK_BY_ID = gql`
    ${DECK_FRAGMENT}
    query getDeckById($deckID: ID!) {
        getDeckById(deckID: $deckID) {
            ...deckInfo
            learning
            tag {
                progress
                pendingReviewCount
            }
        }
    }
`

export const GET_DECK_BY_ID_NO_USER = gql`
    ${DECK_FRAGMENT}
    query getDeckByIdNoUser($deckID: ID!) {
        getDeckById(deckID: $deckID) {
            ...deckInfo
        }
    }
`

export const ALL_DECKS = gql`
    ${DECK_FRAGMENT}
    query allDecks {
        allDecks {
            ...deckInfo
            learning
            tag {
                progress
                pendingReviewCount
            }
        }
    }
`

export const ALL_DECKS_NO_USER = gql`
    ${DECK_FRAGMENT}
    query allDecksNoUser {
        allDecks {
            ...deckInfo
        }
    }
`

export const Discover = () => {
    const user = useContext(UserContext)
    const { loading, error, data, refetch } = useQuery<{ allDecks: Deck[] }>(user ? ALL_DECKS : ALL_DECKS_NO_USER)
    const history = useHistory()

    if (error || loading || !data) {
        return <DataNotLoaded {...{ loading, error, data }} />
    }

    const deckList = _.orderBy(data['allDecks'], (deck) => deck.title, 'desc').filter((x) => !x.learning)

    return (
        <DeckHolder>
            {deckList.map((deck) => (
                <DeckItemPresenter
                    key={deck.title}
                    {...deck}
                    onClick={() => {
                        history.push(`/preview/${deck.id}`)
                        refetch()
                    }}
                    mini_left={(deck as any).tag.count + ' cards'}
                    mini_right={formatPrice(deck?.price)}
                /> //should probably refetch.
            ))}
            {_.range(10).map((i) => (
                <DeckInternal key={i} />
            ))}
        </DeckHolder>
    )
}

const formatPrice = (price: number | null) => {
    if (!price) {
        return 'Free'
    } else if (price === -1) {
        return 'Paid' // patron
    } else return `$${Math.floor(price / 100)}`
}
