import { ApolloClient, ApolloLink, ApolloProvider, gql, HttpLink, InMemoryCache, useMutation, useQuery } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { Button } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import { createMuiTheme, StylesProvider, ThemeProvider } from '@material-ui/core/styles'
import { CaptureConsole } from '@sentry/integrations'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import amplitude from 'amplitude-js'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { BrowserRouter, Redirect, Route, Switch, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Impersonate } from './Admin/Impersonate'
import { BrowseUsers } from './Admin/BrowseUsers'
import { TopLevelIndividualUserStats } from './Admin/IndividualUserStats'
import { TopLevelMultiCardStats } from './Admin/MultiCardStats'
import { MultiUserStats, TopLevelMultiUserStats } from './Admin/MultiUserStats'
import { OOMStats } from './Admin/OOMStats'
import './App.css'
import { TopLevelDeckPreviewer } from './DeckPreview/DeckPreview'
import { Discover } from './DiscoverPage'
import DrawerInternal from './Drawer'
import { GQLMeQuery, GQLResetUserMutation } from './generated/client-graphql-types'
import { Landing } from './Landing'
import { doLogin, UserContext } from './Login'
import { TopLevelDeckReviewer, TopLevelDeckTryer } from './reviewer/DeckReviewer'
import { backendURL } from './shared/sharedutil'
import SRSAppBar from './SRSAppBar'
import { Study } from './StudyPage'
import { TopLevelCardViewer, TopLevelTagViewer } from './TagViewer'
import { useStayUpdated, useWindowSize } from './Utils'
import { CohortRetentionMetrics, TopLineMetrics, TotalWrongCards } from './Admin/TopLineMetrics'
import { TopLevelDeckSettings, TopLevelSecretDeckSettings } from './DeckPreview/DeckSettings'
//import LogRocket from 'logrocket'
import { SnackbarProvider } from 'notistack'
import _ from 'lodash'
import { TopLevelDeckManager } from './Management/DeckManagement'
import { TopLevelTimingDashboard } from './Admin/Timing'
import { Helmet } from 'react-helmet'
import { QuantizedHelmet } from './Helmet'
import preval from 'preval.macro'
//import GitInfo from 'react-git-info/macro'

//console.log(schema)

const isProd = process.env.NODE_ENV === 'production'

const buildTime: number = preval`module.exports = new Date().getTime()`
//const gitInfo = GitInfo().commit.shortHash
//const versionString = `${gitInfo} (${buildTime})`
const versionString = `qvt-${buildTime}` //buildTime.replaceAll(/[^01234567890]/g, '')

const errorLink = onError(({ graphQLErrors, networkError }) => {
    //console.log('error link', graphQLErrors, networkError)
    if (graphQLErrors && isProd) {
        //console.log('inside clause')
        console.error(graphQLErrors)
        doLogin()
        //if (process.env.NODE_ENV === 'production') {
        //window.location.replace(`${backendURL}/login`)
        //}
    }
    if (networkError) {
        console.error(JSON.stringify(networkError))
    }
})

const httpLink = new HttpLink({
    uri: backendURL + '/graphql',
    credentials: 'include',
})

const link = ApolloLink.from([errorLink, httpLink])

const apolloCache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                toReview: {
                    keyArgs: ['tagID'],
                    merge: (existing = [], incoming = [], { readField }) => {
                        //console.log(existing, incoming)
                        return _(existing)
                            .concat(incoming)
                            .uniqBy((c) => readField<string>('id', c))
                            .value()
                    },
                },
            },
        },
        Course: {
            fields: {
                settings: {
                    merge: (existing = {}, incoming = {}) => {
                        const out = {}
                        _.merge(out, existing, incoming) // use the deep merge to merge settings internal property properly.
                        //console.log(existing, incoming, out)
                        return out
                    },
                },
            },
        },
    },
})

const apolloClient = new ApolloClient({
    link: link,
    cache: apolloCache,
})

const MainContainer = styled.div`
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
`

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#FFFFFF',
        },
        secondary: {
            main: '#888888',
        },
        type: 'light',
    },
    typography: {
        fontFamily: 'Roboto',
    },
})

export const ME = gql`
    query me {
        me {
            displayName
            id
            email
            stats {
                points
                streak
            }
            picture
            createdTime
            settings {
                rolloverSecond
                tzOffsetSeconds
                needsDeckWelcome
                needsCommitmentScreen
            }
        }
    }
`

const RESET_USER = gql`
    mutation resetUser {
        reset {
            success
        }
    }
`

const ResetAll = () => {
    const [mutate, mutationData] = useMutation<GQLResetUserMutation>(RESET_USER)
    const history = useHistory()
    return (
        <Button
            onClick={() => {
                mutate({
                    refetchQueries: [{ query: ME }],
                })
                history.push('/')
            }}
        >
            Reset this user (deletes all progress).
        </Button>
    )
}

const SideDrawer = styled.div`
    margin-top: 64px;
    width: 240px;
    position: fixed;
`

const FauxDrawer = styled.div`
    width: 240px;
`

const FauxAppBar = styled.div`
    height: 64px;
    display: grid;
    padding: 15px 15px;
    height: 64px;
    width: 100%;
    grid-column-start: span 2;
`

export const DrawerContext = React.createContext<boolean | undefined>(undefined)

const App = () => {
    const history = useHistory()
    useEffect(() => {
        return history.listen((_) => {
            amplitude.getInstance().logEvent('app:url-change')
        })
    }, [history])

    const { error, loading, data } = useQuery<GQLMeQuery>(ME)
    const { width, height } = useWindowSize()
    const [lastWidth, setLastWidth] = useState<number | undefined>(width)
    const fullSizeDevice = !isMobile && (!width || width > 800)
    const [drawerOpen, setDrawerOpen] = useState<boolean>(fullSizeDevice)
    useEffect(() => {
        if (!width || !lastWidth) {
            return
        }
        if (width < 800 && lastWidth >= 800) {
            setDrawerOpen(false)
            setLastWidth(width)
        } else if (width > 800 && lastWidth <= 800) {
            setDrawerOpen(true)
            setLastWidth(width)
        }
    }, [width])
    const toggle = () => setDrawerOpen(!drawerOpen)
    const user = data?.me ? data.me : null

    useEffect(() => {
        // It's called "deckId" but it actually is used to track Discord users
        const discordId = new URLSearchParams(window.location.search).get('deckId')
        if (discordId) {
            // further obfuscation
            window.localStorage.setItem('quantizedDeckInfo', discordId)
        }

        if (isProd && window.navigator.platform !== 'Linux x86_64' && user !== null) {
            //LogRocket.init('w1evkz/quantized')
        }
    }, [user])

    useStayUpdated()

    const showMain = !isMobile || !drawerOpen

    if (window.location.href.includes('quantized.co./')) {
        window.location.href = window.location.href.replace('quantized.co./', 'quantized.co/')
    }

    if (window.localStorage['redirect_url']) {
        const nextAddr = window.localStorage['redirect_url']
        delete window.localStorage['redirect_url']
        return <Redirect to={nextAddr} />
    }

    return (
        //@ts-ignore
        <UserContext.Provider value={user}>
            <DrawerContext.Provider value={drawerOpen}>
                <MainContainer>
                    <QuantizedHelmet></QuantizedHelmet>
                    <FauxAppBar></FauxAppBar>
                    <Route render={() => <SRSAppBar user={user as any /* @ts-ignore */} toggleDrawerOpen={toggle} />} />
                    {drawerOpen ? (
                        <>
                            <FauxDrawer></FauxDrawer>
                            <SideDrawer>
                                <DrawerInternal onClick={isMobile ? toggle : () => {}} />
                            </SideDrawer>
                        </>
                    ) : (
                        <div></div>
                    )}
                    {showMain && (
                        <Switch>
                            <Route path="/" exact component={Landing} />
                            <Route path="/preview/:deckID/unlock/:secret" exact component={TopLevelDeckPreviewer} />
                            <Route path="/preview/:deckID" exact component={TopLevelDeckPreviewer} />
                            <Route path="/deck/:deckID/settings/" component={TopLevelDeckSettings} />
                            <Route path="/deck/:deckID/manage/" component={TopLevelDeckManager} />
                            <Route path="/reset" exact component={ResetAll} />
                            <Route path="/browse" exact component={Discover} />
                            <Route path="/study" exact component={Study} />
                            <Route path="/usage" exact component={MultiUserStats} />
                            <Route path="/performance" exact component={TopLevelTimingDashboard} />
                            <Route path="/usage/oom" exact component={OOMStats} />
                            <Route path="/usage/filter/:whitelist" exact component={TopLevelMultiUserStats} />
                            <Route path="/usage/tag/:tagID/cards" exact component={TopLevelMultiCardStats} />
                            <Route path="/usage/topline" exact component={TopLineMetrics} />
                            <Route path="/usage/cohort" exact component={CohortRetentionMetrics} />
                            <Route path="/usage/wrongcount" exact component={TotalWrongCards} />
                            <Route path="/usage/:userId" exact component={TopLevelIndividualUserStats} />
                            <Route path="/usage/:userId/tag/:tagId" exact component={TopLevelIndividualUserStats} />
                            <Route path="/browse_users" exact component={BrowseUsers} />
                            <Route path="/review/:deckID" exact component={TopLevelDeckReviewer} />
                            <Route path="/view/tag/:tagID/index/:index" exact component={TopLevelTagViewer} />
                            <Route path="/view/tag/:tagID" exact component={TopLevelTagViewer} />
                            <Route path="/view/card/:cardId" exact component={TopLevelCardViewer} />
                            <Route path="/try/:deckID" exact component={TopLevelDeckTryer} />
                            <Route path="/impersonate" exact component={Impersonate} />
                        </Switch>
                    )}
                </MainContainer>
            </DrawerContext.Provider>
        </UserContext.Provider>
    )
}

export const WrappedApp = () => (
    <BrowserRouter>
        <ApolloProvider client={apolloClient}>
            <StylesProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider maxSnack={5}>
                        <CssBaseline>
                            <App />
                        </CssBaseline>
                    </SnackbarProvider>
                </ThemeProvider>
            </StylesProvider>
        </ApolloProvider>
    </BrowserRouter>
)

if (isProd) {
    Sentry.init({
        dsn: 'https://1e4c5fcc134b41c5bf2313831cc1180a@o796842.ingest.sentry.io/5802191',
        integrations: [
            new Integrations.BrowserTracing(),
            new CaptureConsole({
                levels: ['error'],
            }),
        ],
        tracesSampleRate: 0.001,
        environment: process.env.NODE_ENV,
        release: versionString,
    })
}
