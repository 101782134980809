import { List, ListItem, ListItemIcon } from '@material-ui/core'
import BookOpenPageVariant from 'mdi-material-ui/BookOpenPageVariant'
import Compass from 'mdi-material-ui/Compass'
import ViewGridPlus from 'mdi-material-ui/ViewGridPlus'
import React, { useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { UserContext } from './Login'

const ListItemText = styled.p`
    font-size: 14px;
    font-weight: 500;
`

const ListItemInternal = styled.div`
    display: grid;
    padding: 10px;
    grid-template-columns: auto 1fr;
    width: 100%;
`

const DrawerItem = (props: {
    onClick: () => void
    link?: string
    href?: string
    text: string
    icon: React.FunctionComponent
}) => {
    const location = useLocation()
    const selected = location.pathname.startsWith('/' + props.link)
    const history = useHistory()
    return (
        <ListItem
            selected={selected}
            button
            onClick={() => {
                if (props.href) {
                    window.location.href = props.href
                } else {
                    history.push('/' + props.link)
                    props.onClick()
                }
            }}
        >
            <ListItemInternal>
                <ListItemIcon>{React.createElement(props.icon)}</ListItemIcon>
                <ListItemText>{props.text}</ListItemText>
            </ListItemInternal>
        </ListItem>
    )
}

const DrawerInternal = ({ onClick }: { onClick: () => void }) => {
    const user = useContext(UserContext)

    return (
        <List>
            <DrawerItem icon={Compass} text="Discover" link="browse" onClick={onClick} />
            {user && <DrawerItem icon={BookOpenPageVariant} text="Your decks" link="study" onClick={onClick} />}
            <DrawerItem
                icon={ViewGridPlus}
                text="Submit a deck"
                href="mailto:polysift@gmail.com?subject=An Anki Deck I Want To Submit"
                onClick={onClick}
            />
        </List>
    )
}
//<DrawerItem icon={TextSubject} text="Blog" href="https://blog.polysift.com" onClick={() => {}}/>
//<DrawerItem icon={Poll} text="Stats" link="stats" onClick={onClick} />

export default DrawerInternal
