import { useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { REVIEW_TOO_LONG_TIME_MS } from '../shared/sharedutil'
import { FullUserStats } from '../shared/types'
import { DataNotLoaded } from '../Utils'
import { GET_ALL_USERS_FULL_STATS } from './MultiUserStats'
import { useAllReviews } from './TopLineMetrics'

export const OOMStats = () => {
    const { data, error, loading } = useAllReviews()

    if (error || loading || !data) {
        return <DataNotLoaded {...{ loading, error, data }} />
    }

    const reviews = data
    const totalReviews = reviews.length
    const totalMS = _(reviews)
        .filter((x) => x.totalTimeMS < REVIEW_TOO_LONG_TIME_MS)
        .sumBy((r) => r.totalTimeMS)

    const totalOOM = Math.log(totalReviews) / Math.log(10)
    const OOMLeft = Math.round((6 - totalOOM) * 100) / 100

    return (
        <OOMStatsContainer>
            <OOMStatsLine>Total reviews: {reviews.length}</OOMStatsLine>
            <OOMStatsLine>Total time: {dayjs.duration(totalMS, 'milliseconds').humanize()}</OOMStatsLine>
            <OOMStatsLine>OOM to 1 million: {OOMLeft}</OOMStatsLine>
        </OOMStatsContainer>
    )
}

const OOMStatsLine = styled.div`
    font-size: 20pt;
`

const OOMStatsContainer = styled.div`
    display: grid;
    justify-content: center;
    align-content: center;
    align-items: center;
`
