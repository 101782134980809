import { gql, useMutation } from '@apollo/client'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tooltip,
    Typography,
    Zoom,
} from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import ButtonBase from '@material-ui/core/ButtonBase'
import IconButton from '@material-ui/core/IconButton'
import { styled as muistyled } from '@material-ui/core/styles'
import BlurCircularIcon from '@material-ui/icons/BlurCircular'
//import EmailIcon from '@material-ui/icons/EmailOutlined'
import BugIcon from '@material-ui/icons/BugReportOutlined'
import MenuIcon from '@material-ui/icons/MenuOutlined'
import RefreshIcon from '@material-ui/icons/Refresh'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { GQLUpgradeCardsMutation } from './generated/client-graphql-types'
import { Login } from './Login'
import { User } from './shared/types'

const AppBarInternal = styled.div`
    display: grid;
    grid-template-columns: auto auto 1fr auto auto;
    grid-gap: 15px;
    padding: 15px 15px;
    height: 64px;
    width: 100%;
    align-self: center;
    align-content: center;
    align-items: center;
`

const AppbarRightIcons = styled.div`
    display: grid;
    align-self: center;
    align-content: center;
    align-items: center;
    grid-auto-flow: column;
`

const Title = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;

    letter-spacing: 0.15px;

    color: rgba(95, 99, 104, 0.87);

    mix-blend-mode: normal;
    opacity: 0.87;
`

const Beta = styled.div`
    opacity: 0.5;
    display: inline;
    font-size: 15px;
    font-weight: 300;
`

const StyledAppBar = muistyled(AppBar)({
    zIndex: 1201,
    borderBottom: 'lightgrey 1px solid',
})

const CustomAppBar = styled.div`
    grid-column-start: span 2;
    border-bottom: lightgrey 1px solid;
    background-color: white;
    position: fixed;
    width: 100%;
    z-index: 1000;
`
//position="relative" elevation={0}>
const UPGRADE_CARDS = gql`
    mutation upgradeCards($tagID: ID) {
        upgradeCards(tagID: $tagID) {
            success
        }
    }
`

const SRSAppBar = ({ user, toggleDrawerOpen }: { user: User; toggleDrawerOpen: () => void }) => {
    const [upgradeCards, _] = useMutation<GQLUpgradeCardsMutation>(UPGRADE_CARDS)

    const showRefresh = user?.email === 'robertvcunningham@gmail.com' || process.env.NODE_ENV === 'development'

    return (
        <CustomAppBar>
            <AppBarInternal>
                <IconButton onClick={toggleDrawerOpen}>
                    <MenuIcon />
                </IconButton>
                <ButtonBase component={Link} to="/">
                    <Typography variant="h5">
                        <Title>
                            Quantized <Beta>beta v1.10</Beta>
                        </Title>
                    </Typography>
                </ButtonBase>
                <div></div>
                <AppbarRightIcons>
                    {showRefresh && (
                        <IconButton
                            color="inherit"
                            onClick={async () => {
                                await upgradeCards()
                            }}
                        >
                            <RefreshIcon style={{ opacity: 0.54 }} />
                        </IconButton>
                    )}
                    <Tooltip
                        title={`Click here to file a bug report or request a feature!`}
                        placement="top"
                        TransitionComponent={Zoom}
                        arrow
                    >
                        <IconButton
                            color="inherit"
                            href="mailto:polysift@gmail.com?subject=Bug / Suggestion / Request"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <BugIcon style={{ opacity: 0.54 }} />
                        </IconButton>
                    </Tooltip>
                </AppbarRightIcons>
                <Login />
            </AppBarInternal>
        </CustomAppBar>
    )
}
//<IconButton color="inherit" onClick={() => fetch('upgrade')}>
//    <SettingsIcon style={{ opacity: 0.54 }} />
//</IconButton>
//<Button component={Link} to="/browse" color="inherit">Decks</Button>

/*
const PointMeter = (props: { user: User }) => {
    const [open, setOpen] = useState(false)

    return (
        <>
            <ButtonBase onClick={() => setOpen(true)}>
                <PointMeterInternal>
                    <BlurCircularIcon />
                    <div>{props.user.stats.points}</div>
                    <div>(x{Math.ceil(Math.log2(props.user.stats.streak + 1))})</div>
                </PointMeterInternal>
            </ButtonBase>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>Points</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You currently have <b>{props.user.stats.points} points</b>, with a streak of{' '}
                        <b>{props.user.stats.streak}</b>, for a multiplier of{' '}
                        <b>{Math.ceil(Math.log2(props.user.stats.streak + 1))}</b>. <hr></hr>
                        Every time you answer a card correctly, you gain (5-s) points, where s is the number of seconds required
                        to answer the card. If a card takes more than 5 seconds to answer, you gain no points. Your streak is the
                        number of cards you've answered correctly consecutively. The point value of every card you answer is
                        multiplied by log(streak).
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => setOpen(false)}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
*/

const PointMeterInternal = styled.div`
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 1fr 1fr 1fr;
`

export default SRSAppBar
