import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { Switch, Route } from 'react-router-dom'
import { GQLGetDeckMetaByIdQuery } from './generated/client-graphql-types'

export const GET_DECK_META_BY_ID = gql`
    query getDeckMetaById($deckID: ID!) {
        getDeckById(deckID: $deckID) {
            id
            title
            author
            description
            flavor
            image
            price
            externalURL
            permissions
        }
    }
`

export const QuantizedHelmet = () => (
    <Switch>
        <Route path={['/deck/:deckID', '/preview/:deckID', '/review/:deckID', '/try/:deckID']}>
            <InnerQuantizedHelmet></InnerQuantizedHelmet>
        </Route>
        <Route path="/">
            <Helmet>
                <title>Quantized</title>
            </Helmet>
        </Route>
    </Switch>
)

export const InnerQuantizedHelmet = () => {
    const { deckID } = useParams<{ deckID: string }>()
    const { data, error, loading } = useQuery<GQLGetDeckMetaByIdQuery>(GET_DECK_META_BY_ID, {
        variables: {
            deckID,
        },
    })

    if (!deckID || !data || error || loading)
        return (
            <Helmet>
                <title>Quantized</title>
            </Helmet>
        )

    const deck = data.getDeckById

    return (
        <Helmet>
            <title>{deck?.title} - Quantized</title>
        </Helmet>
    )
}
