import { getApolloContext } from '@apollo/client'
import { Switch, TextField } from '@material-ui/core'
import React, { useContext } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import { useMutateCourseSettings } from '../DeckPreview/DeckSettings'
import { useCourseSettings } from '../reviewer/DeckReviewer'
import { NEW_CARD_STRATEGY, TagID } from '../shared/types'
import { DataNotLoaded } from '../Utils'
import { DeckSettingsReset, StandardSetting } from './ResetDeck'

export const useResetToReview = () => {
    const { client: apolloClient } = useContext(getApolloContext())
    return () => {
        if (apolloClient) {
            apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'toReview' })
        } else {
            console.error('apolloClient was undefined when we tried to evict toReview.')
        }
    }
}

export const AdvancedSettings = () => {
    const { deckID } = useParams<{ deckID: TagID }>()

    const mutateCourseSettings = useMutateCourseSettings(deckID)
    const { error, loading, data } = useCourseSettings(deckID)

    console.log(error, loading, data?.custom)
    if (!data) {
        return <DataNotLoaded {...{ loading, error, data }} />
    }
    //if (error || loading || !data) {
    //    return <DataNotLoaded {...{ loading, error, data }} />
    //}

    //console.log(data.custom)

    const overridden = data.custom.newCardStrategy === NEW_CARD_STRATEGY.FixedCardsPerDay
    //console.log('or', overridden)

    return (
        <SettingsContainer>
            <StandardSetting
                header="Force Manual Scheduling"
                explanation="Force Quantized to assign you the same number of cards each day. If enabled, this overrides your settings in the New Cards pane."
            >
                <ColoredSwitch
                    checked={overridden}
                    onChange={async (e) => {
                        await mutateCourseSettings({
                            custom: {
                                newCardStrategy: e.target.checked
                                    ? NEW_CARD_STRATEGY.FixedCardsPerDay
                                    : NEW_CARD_STRATEGY.FixedTimePerDay,
                            },
                        })
                    }}
                ></ColoredSwitch>
            </StandardSetting>
            <StandardSetting
                header="New Cards Per Day"
                explanation="How many new cards we should assign you from this deck each day."
                disabled={!overridden}
            >
                <TextField
                    disabled={!overridden}
                    type="number"
                    margin="dense"
                    color="secondary"
                    variant="outlined"
                    label="New Cards Per Day"
                    value={data.custom.cardsPerDay || ''}
                    onChange={async (e) => {
                        await mutateCourseSettings({
                            custom: { cardsPerDay: parseCardsPerDay(e.target.value) },
                        })
                    }}
                ></TextField>
            </StandardSetting>
            <DeckSettingsReset></DeckSettingsReset>
        </SettingsContainer>
    )
}

const parseCardsPerDay = (input: string) => {
    const float = parseFloat(input)
    if (float <= 0) {
        return 0
    } else if (float > 200) {
        return 200
    } else {
        return Math.round(float)
    }
}

const ColoredSwitch = styled(Switch)`
    .switchBase {
        color: 'blue';
    }
    &checked {
        color: 'blue';
    }
`

const SettingsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    align-content: start;
    grid-gap: 15px;
`
