import { useQuery } from '@apollo/client'
import _ from 'lodash'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { ClientCard } from '../client_types'
import { GQLGetCardsFromTagQuery } from '../generated/client-graphql-types'
import { AnswerType, CardID, FullReviewInfo, FullUserStats } from '../shared/types'
import { GET_CARDS_FROM_TAG } from '../TagViewer'
import { DataNotLoaded } from '../Utils'
import { GET_ALL_USERS_FULL_STATS } from './MultiUserStats'

export const TopLevelMultiCardStats = (props: any) => <MultiCardStats tagID={props.match.params.tagID}></MultiCardStats>

const MultiCardStats = ({tagID}: {tagID: string}) => {
    const { error, loading, data } = useQuery(GET_ALL_USERS_FULL_STATS)

    const { data: cardsData} = useQuery<GQLGetCardsFromTagQuery>(GET_CARDS_FROM_TAG, {
        variables: { tagID: tagID },
        errorPolicy: 'all',
    })

    if (error || loading || !data || !cardsData) {
        return <DataNotLoaded {...{ loading, error, data }} />
    }

    const cards = cardsData.getTagByID.cards as ClientCard[]

    const parsed: FullUserStats[] = JSON.parse(data.getAllUserStats)
    const reviews = _.flatMap(parsed, (fus: FullUserStats) => fus.reviews).filter(r => r.userId === '0471bdde-67b2-493b-b607-ee6fa729d9c4')
    const reviewsByCardID = _.groupBy(reviews, (r: FullReviewInfo) => r.cardId)

    const cardsWithReviews: { reviews: FullReviewInfo[], id: string, index: number }[] = _(cards).sortBy('index').map(o => ({
        reviews: reviewsByCardID[o.id] || [],
        id: o.id,
        index: o.index,
    })).value()

    return (
        <MultiCardContainer>
            {cardsWithReviews.map((c) => (
                <IndividualCard index={c.index} key={c.id} reviews={c.reviews} cardId={c.id}></IndividualCard>
            ))}
        </MultiCardContainer>
    )
}

const MultiCardContainer = styled.div`
    display: grid;
    grid-gap: 5px;
    grid-auto-flow: row;
    grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
    margin: 20px;
`

const IndividualCard = ({ reviews, cardId, index }: { reviews: FullReviewInfo[], cardId: CardID, index: number }) => {
    const history = useHistory()
    const correct = reviews.filter(r => r.answer === AnswerType.Remembered).length / reviews.length
    return (
        <IndividualCardContainer
            onClick={() => window.open(`/view/card/${cardId}`)}
            //color={`hsl(${3 * Math.round((correct-.6) * 100)}, ${5 * reviews.length}%, 80%)`}
            color={`hsl(${3 * Math.round((correct-.6) * 100)}, ${6 * reviews.filter(r => r.answer === AnswerType.Forgot).length}%, 80%)`}
        >
            <div> {reviews.length}r </div>
            <div> {Math.round(correct*100)}%c </div>
            <div> {index}i </div>
        </IndividualCardContainer>
    )
}

const IndividualCardContainer = styled.div.attrs((props) => ({
    style: {
        backgroundColor: props.color,
    },
}))`
    width: 40px;
    height: 40px;
    border-radius: 5px;
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-size: 8px;
`
