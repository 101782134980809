export interface User {
    id: UserID
    displayName: string
    picture: string
    settings: UserSettings
    email: string | null
    createdTime: Date
}

export interface Course {
    id: TagID
    settings: CourseSettings
    meta: TagMeta | null
}

export interface CourseSettings {
    minutes: number | null
    explicitlyStudying: boolean
}

export interface UserSettings {
    rolloverSecond: number
    tzOffsetSeconds: number
    needsDeckWelcome: boolean
    needsCommitmentScreen: boolean
}

export enum AnswerType {
    Remembered = 'Remembered',
    Forgot = 'Forgot',
    None = 'None', // This is the undefined value.
    Suspended = 'Suspended',
    //Seen = 'Seen',
}

export enum CardStatus {
    New = 'New',
    Learning = 'Learning',
    Memorized = 'Memorized',
    //Seen = 'seen',
}

export enum ContentDifficulty {
    Easy = 1,
    Normal = 2,
    Difficult = 3,
}

export enum CardType {
    Info = 'Info',
    Intro = 'Intro',
    Card = 'Card',
}

export interface Card {
    id: CardID
    index: number
    source: string
    tags: Tag[]
    type: CardType
}

export interface CardAttachments {
    comments: UserComment[]
}

export type TagID = string
export type UserID = string
export type CardID = string
export type CommentID = string

export interface Tag {
    id: TagID
    parentId: TagID | null
}

//export type CardHistory = {
//    history: ClientReviewInfo[]
//}

export interface CardSchedule {
    userId: UserID
    cardId: CardID
    status: CardStatus
    interval: Seconds
    //index: number
    //tags: string[]
    //tags: any[] // gets transformed from string to {id: string}[] for gql
    due: Date | null
    firstSeen: Date | null
    timesSeen: number
}

//export type CoreCardSchedule = Pick<CardSchedule, 'status' | 'interval' | 'due' | ''

export type ClientCardSchedule = Pick<CardSchedule, 'status' | 'interval' | 'timesSeen'> & {
    id: CardID
    due: string
    firstSeen: string
}

// export type UnseenCardSchedule = {
//     status: CardStatus.New
// } & BaseCardSchedule
//
// export type SeenCardSchedule = {
//     status: CardStatus.Memorized | CardStatus.Learning
//     orphaned: boolea
//     due: number
//     firstSeen: number
// } & BaseCardSchedule
//
// export type CardSchedule = UnseenCardSchedule | SeenCardSchedule

export enum UserType {
    Puppet = 'Puppet',
    RealPerson = 'RealPerson',
    CommentBot = 'CommentBot',
}

export interface UserComment {
    id: CommentID
    cardId: CardID
    authorId: UserID
    author?: User
    timeCreated: Date
    timeModified: Date | null
    contents: string
    likes: number
    authorType: UserType
}

export interface ClientReviewInfo {
    reviewedAt: Date
    answer: AnswerType
    frontTimeMS: Milliseconds
    totalTimeMS: Milliseconds
}

export interface ServerReviewInfo {
    cardId: CardID
    userId: UserID

    beforeInterval: Seconds
    beforeDue: Date | null
    beforeStatus: CardStatus
    beforeTimesSeen: number
}

export type FullReviewInfo = ClientReviewInfo & ServerReviewInfo

export interface FullUserStats {
    reviews: FullReviewInfo[]
    /*lefts: { newLeft: number; learningLeft: number; memorizedLeft: number; time: number }[]*/
    user: User
    courses?: any
}

export type Hours = number
export type Seconds = number
export type Minutes = number
export type Milliseconds = number
export type SRSDay = number
export type UnixTimeStamp = number
export type UnixTimeStampMillis = number

// Deck is just a tag, but it also has additional meta fields.
export type Deck = TagMeta & Tag & { tag: Tag } & { learning?: boolean; locked?: boolean } // this is a throwaway type, eventually replace it with Tag & TagMeta etc

export type TagMeta = {
    title: string
    author: string
    description: string | null
    flavor: string | null
    image: string | null
    price: number | null
    changelog: ChangelogItem[] | null
    faq: FAQItem[] | null
    externalURL?: string | null
}

export type FAQItem = {
    question: string
    answer: string
}

export type ChangelogItem = {
    info: string
    version: string
    date: string
}

export type CommentBotID = string

export type CommentBot = {
    id: CommentBotID
    displayName: string
    picture: string
}

export type MachineComment = {
    cardId: CardID
    authorId: CommentBotID
    contents: string
    author: CommentBot
}

export enum APIErrorStrings {
    NOT_LOGGED_IN = 'Not logged in.',
    FORBIDDEN = 'Forbidden.',
}

export type CustomSettings = {
    cardsPerDay: number
    newCardStrategy: NEW_CARD_STRATEGY
}

export enum NEW_CARD_STRATEGY {
    FixedTimePerDay = 'fixedTimePerDay',
    FixedCardsPerDay = 'fixedCardsPerDay',
}
