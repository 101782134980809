import { useQuery } from '@apollo/client'
import { Button } from '@material-ui/core'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { ClientComment } from '../client_types'
import { useSecrets } from '../DeckPreview/DeckPreview'
import { GET_DECK_BY_ID_NO_USER } from '../DiscoverPage'
import { GQLGetAllCardsQuery, GQLGetDeckByIdNoUserQuery } from '../generated/client-graphql-types'
import { doLogin } from '../Login'
import { Card, CardAttachments, CardType } from '../shared/types'
import { LiveCardMeta } from '../types'
import { DataNotLoaded } from '../Utils'
import { ReviewableCard } from './Card'
import { GET_ALL_CARDS } from './DeckReviewer'
import { ReviewerContainer } from './ReviewerShared'
import { DoneTryItScreen } from './WelcomeFlow'

export const UnsavedProgressDiv = styled.div`
    background-color: papayawhip;
    width: 100%;
    padding: 10px;
    margin: auto;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
`

const UnsavedProgressWarning = () => {
    const history = useHistory()

    return (
        <UnsavedProgressDiv>
            Your progress is not being saved. Sign in to save your progress.
            <Button onClick={doLogin(history.location.pathname.replace('try', 'preview'))} variant="outlined">
                Sign in
            </Button>
        </UnsavedProgressDiv>
    )
}

const LockedProgressWarning = ({ externalUrl }: { externalUrl: string }) => (
    <UnsavedProgressDiv>
        Your progress is not being saved. Buy this deck to save your progress.
        <Button href={externalUrl} variant="outlined">
            Buy
        </Button>
    </UnsavedProgressDiv>
)

export const TryitReviewer = ({ deckID }: { deckID: string }) => {
    type TryCard = Omit<Card & CardAttachments, 'index' | 'tags' | 'comments'> & { comments: ClientComment[] }
    const { loading, error, data } = useQuery<GQLGetAllCardsQuery>(GET_ALL_CARDS, {
        variables: { tagID: deckID },
    })
    const {
        loading: loading2,
        error: error2,
        data: deckData,
    } = useQuery<GQLGetDeckByIdNoUserQuery>(GET_DECK_BY_ID_NO_USER, {
        variables: { deckID: deckID },
    })

    const deck = deckData?.getDeckById

    const [index, setIndex] = useState(0)

    const [shuffledCards, setShuffledCards] = useState<TryCard[] | undefined>()

    useEffect(() => {
        if (!data) {
            return
        }
        //@ts-ignore
        let cards: TryCard[] = data.getAllCards.map((c) => ({ ...c, type: c.type as unknown as CardType }))
        setShuffledCards(_.shuffle(cards.filter((x) => x.type !== CardType.Intro)))
        //setShuffledCards(cards.filter(x => x.type !== "intro"))
    }, [data])

    const [storedDeckSecret, setStoredDeckSecret] = useSecrets(deck?.id || '')

    if (error2 || error || loading || loading2 || !(data && shuffledCards && deckData)) {
        return <DataNotLoaded {...{ loading, error, data: data && shuffledCards }} />
    }

    const activeCard = shuffledCards![index]

    const lcmOverrides: (l: LiveCardMeta) => LiveCardMeta = (lcmDefault) => ({
        ...lcmDefault,
        onNext: () => {
            setIndex(index + 1)
        },
        intervals: {
            remembered: 'Never',
            forgot: 'Never',
        },
    })

    if (index >= shuffledCards!.length) {
        return <DoneTryItScreen></DoneTryItScreen>
    }

    return (
        <ReviewerContainer>
            <ReviewableCard
                key={activeCard.id}
                /* @ts-ignore */
                card={activeCard}
                metaComponent={() =>
                    deck?.locked && !storedDeckSecret ? (
                        <LockedProgressWarning externalUrl={deck?.externalURL!} />
                    ) : (
                        <UnsavedProgressWarning />
                    )
                }
                overrides={lcmOverrides}
            />
        </ReviewerContainer>
    )
}
